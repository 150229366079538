import React, { useState, useContext, useEffect } from 'react'
import { StateContext } from '../../../state';
import { StateDeviceContext } from '../../../state_device';
import http from '../../../../../utils/server'
import { Row, Col, Button, Spin, Modal, Input, Space, Checkbox, Divider, DatePicker, Tabs, message, InputNumber, notification} from 'antd'
import moment from 'moment';
import { ExclamationCircleOutlined} from '@ant-design/icons'
import './device.less'
import { Mode } from '../../../../../App';
import md5 from 'js-md5';
import Sign from '../../../sign';
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const intReg =  /^(0|1?\d{0,2}|2[0-4]\d|25[0-5])$/;

function formatTime(time){
    if(time){
       var strs= time.split(':');
       if(strs.length<2) return time;   
       strs[0].length===1&&(strs[0]='0'+strs[0]);
       strs[1].length===1&&(strs[1]='0'+strs[1]);
       return strs[0]+':'+strs[1];
    }
}
function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute ;
};
function DeviceSet(props) {
    const { state, dispatch } = useContext(StateContext)
    const { state_device, dispatch_device } = useContext(StateDeviceContext)
    const [device, setDevice] = useState(props.item)
    const [deviceS,setDeviceS] = useState(props.item)
    const [devices, setDevices] = useState({type:5,data:[]});
    const [serverIP, setServerIP] = useState({ validity: false, error: false, type: 1 })//IP 
    const [deviceTime, setDeviceTime] = useState({ validity: false, error: false, type: 2 })//设备时间
    const [uploadInterval, setUploadInterval] = useState({ validity: false, error: false, type: 3 })//回传间隔
    const [sensorStop, setSensorStop] = useState({ validity: false, error: false, type: 4 })//停用
    const [sensorAlarm, setSensorAlarm] = useState({ validity: false, error: false, type: 5 })//探头报警参数
    const [powerAlarm, setPowerAlarm] = useState({ validity: false, error: false, type: 6 })//电源
    const [batteryAlarm, setBatteryAlarm] = useState({ validity: false, error: false, type: 7 })//电量
    const [overAlarm, setOverAlarm] = useState({ validity: false, error: false, type: 8 })//温湿度超标报警

    const [store, setStore] = useState({ validity: false, error: false, type: 9 })//存储间隔
    const [revise, setRevise] = useState({ validity: false, error: false, type: 10 })//补偿
    const [devClose, setDevClose] = useState({ validity: true, error: false, type: 11 })//关机
    const [devStronInfo, setDevStronInfo] = useState({ validity: true, error: false, type: 12})//存储信息
    const [load, setLoad] = useState(false)
    const [timeReadDataStart,setTimeReadDataStart] = useState({ validity: false, error: false, value:"" })
    const [timeReadDataEnd,setTimeReadDataEnd] = useState({ validity: false, error: false, value:"" })
    const [offline,setOffLine] = useState({offtime:10,interval:5,count:2});
    const [showAffirm,setShowAffirm] = useState(false);//设置参数身份确认弹窗
    const [affirmPwd,setAffirmPwd] = useState(''); //设置参数确认密码
    const [setState, setSetState] = useState(0);//设置状态 0不能设置 1普通设置 2超级设置
    const [isSet,setIsSet] = useState(false);//是否有设置权限
    const [devList, setDevList] = useState([]);//选中的主机名
    const [selDevNodes,setSelDevNodes] = useState([]);
    const [isShowSet,setIsShowSet] = useState(true);
    const [cmd,setCmd]= useState('');//原始命令
    const [devParams,setDevParams] = useState({})
    const [isStrong,setIsStrong] = useState(false)//补偿范围大小
    const [npw1,setNpw1] = useState('')
    const [npw2,setNpw2] = useState('')
    const [showSgin,setShowSgin] = useState(false)
    const [setType,setSetType] = useState(0)
    const [signEnable,setSignEnable] = useState(false)
    const [mySign,setMySign] = useState('')
    const addEle =(key,value)=> {
        setDevParams(params => ({
          ...params,
          [key]: value
        }));
    }
    useEffect(() => {
        if (window.CefSharp !== undefined) {
            window.CefSharp.BindObjectAsync("bound");
            let myConf=window.bound.getConfig();
            if(myConf.isStrong){
                setIsStrong(true)
            }
        }
        setSetState(state.setState);
        let role = state.main.role;
        setIsSet(role.match('305')===null?false:true)
        let devs = 
            state.main.type<102?  state_device.devicestate.filter(x=>x.Node!==0&&x.District===props.item.District&&
            (x.Type!==205&&x.Type!==207&&x.Type!==209)&&x.Sensor.Hide!==1)
            : 
            state_device.devicestate.filter(x=>x.Node!==0&&x.District===props.item.District&&(Mode==='soc'?x.ExprieStatus!==3:true)&&
            (x.Type!==205&&x.Type!==207&&x.Type!==209)&&x.Sensor.Hide!==1)
        let devNodes = [];
        devs.map(m=>{
            let temp = devNodes.findIndex(x=>x.sn===m.Sn)
            if(temp===-1){
                devNodes.push({sn:m.Sn,name:m.MName,nodes:[{value:m.ID,label:m.Name,key:m.ID,checked:false}],all:false,indeterminate:false})
            }else{
                devNodes[temp].nodes.push({value:m.ID,label:m.Name,key:m.ID,checked:false})
            }
        })
        if(devNodes.length>0){
            let temp = devNodes.findIndex(x=>x.sn===props.item.Sn)
            devNodes[temp].all = true
            devNodes[temp].nodes.map(m=>{
                m.checked = true
            })
            let devsTemp = devs.filter(x=>x.Sn===props.item.Sn);
            let devsData =[{Sn:props.item.Sn,Params:props.item.Params,Sensor:[],StoreInfo:props.item.StoreInfo,Type:props.item.Type,MName:props.item.MName}];
            devsTemp.map(m=>{
                m.Sensor.Name = m.Name
                devsData[0].Sensor.push(m.Sensor)
            })
            let devsInfo = devices;
            devsInfo.data = devsData;
            setDevices(devsInfo)
        }
        devNodes.sort((a,b)=>a.sn-b.sn);
        setDevList(devNodes)
        setSelDevNodes(devs.sort((a,b)=>a.Sn-b.Sn))
    }, [])
    useEffect(()=>{
        let todo = state.mainTodo;
        let todoCon = todo===''?null:JSON.parse(todo)
        if(todoCon&&todoCon.sginEnable){
            setSignEnable(true);
            setMySign(todoCon.mySgin?todoCon.mySgin:'')
        }
    },[state.mainTodo])
    const ReadALL = (t, call) => {
        http.post((props.cloud ? "/v3" : "/v1") + "/getdevice", { ...t, device: device.Sn, node: device.Node }).then(data => {
            if (data.code == 0) {
                call({ ...t, validity: true })
            }
        })
    }
    const ReadDeviceParams = (t, call) => {
        setLoad(true)
        http.post((props.cloud ? "/v3" : "/v1") + "/getdevice", { ...t, device: device.Sn, node: device.Node }).then(data => {
            setLoad(false)
            if (data.code == 0) {
                if(data.data.Params.OverAlarm.StartTime!==""){
                    data.data.Params.OverAlarm.StartTime= formatTime(data.data.Params.OverAlarm.StartTime);
                    data.data.Params.OverAlarm.EndTime= formatTime(data.data.Params.OverAlarm.EndTime);
                }
                data.data.Name = device.Name;
                data.data.MName = device.MName;
                setDevice(data.data);
                call({ ...t, validity: true });
            }
        })
    }
    const ReadDeviceData = () => {
        if(!timeReadDataStart.validity){
            Modal.error({
                title: '参数错误',
                centered: true,
                content: '请检查所设置的参数',
            });
            return
        }
        if(!timeReadDataEnd.validity){
            Modal.error({
                title: '参数错误',
                centered: true,
                content: '请检查所设置的参数',
            });
            return
        }
        setLoad(true)
        http.post((props.cloud ? "/v3" : "/v1") + "/readdevicedata", {  
            device: device.Sn, 
            node: device.Node,
            starttime:timeReadDataStart.value ,
            endtime:timeReadDataEnd.value
        }).then(data => {
            setLoad(false)
            if (data.code == 0) {

            }
        })
    }
    const ReadDeviceDataInt = () => {
        let startTime = timeReadDataStart.value 
        let endTime = timeReadDataEnd.value 
        let startTime1 = startTime
        let timersss=setInterval(() => {
            let endTime1 = formatDateTimes(Date.parse(startTime1)+1800*1000);
            setLoad(true)
            http.post((props.cloud ? "/v3" : "/v1") + "/readdevicedata", {  
                device: device.Sn, 
                node: device.Node,
                starttime:startTime1 ,
                endtime:endTime1
            }).then(data => {
                setLoad(false)
                if (data.code == 0) {
    
                }
            })
            startTime1 = formatDateTimes(Date.parse(startTime1)+1800*1000)
            if (Date.parse(endTime)<Date.parse(endTime1)){
                clearInterval(timersss)
            }
        }, 1000*60);
    }
    const SetDeviceOffLineAlarm = (t) => {
        setLoad(true)
        http.put((props.cloud ? "/v3" : "/v1") + "/deviceoffline/" + device.ID, { alarm: t.alarm ,offtime:t.offtime,interval:t.interval,count:t.count}).then(data => {
            setLoad(false)
            if (data.code == 0) {
                console.log(t);
                device.Sensor.OffLineAlarm=t.alarm;
                device.Sensor.OffLineTime=t.offtime;
                device.Sensor.OffLineAlarmInterval=t.interval;
                device.Sensor.OffLineAlarmCount=t.count;
                setDevice({ ...device})
            }
        })
    }
    const SetDeviceLinkAlarm = (t) => {
        setLoad(true)
        http.put((props.cloud ? "/v3" : "/v1") + "/devicelink/" + device.ID, { alarm: t }).then(data => {
            setLoad(false)
            if (data.code == 0) {
                device.Sensor.LinkAlarm=t;
                setDevice({ ...device})
            }
        })
    }
    const SetDeviceParams = (t,mySgin='') => {
        if (t.error) {
            Modal.error({
                title: '参数错误',
                centered: true,
                content: '请检查所设置的参数',
            });
        } else {
            setLoad(true)
            setTimeout(() => {
                http.post((props.cloud ? "/v3" : "/v1") + "/setdevice", { ...t, device: device },mySgin).then(data => {
                    setLoad(false)
                })
            }, 1000);
        }
    }
    const SetDevicesParams = (mySgin='')=> {
        setLoad(true)
        setTimeout(() => {
            http.post((props.cloud ? "/v3" : "/v1") + "/setdevices", { devices: devices },mySgin).then(data => {
                setLoad(false)
            })
        }, 1000);
    }
    const SetDevicesWarning=()=>{
        let devs = [];
        devices.data.map(m=>{
            m.Sensor.map(n=>{
                devs.push({
                    sn:m.Sn,
                    node:n.Id,
                    temp_warning_high:n.TempWarningHigh,
                    temp_warning_low:n.TempWarningLow,
                    humi_warning_high:n.HumiWarningHigh,
                    humi_warning_low:n.HumiWarningLow,
                    warning_interval:n.WarningInterval
                })
            })
        })
        http.put((props.cloud ? "/v3" : "/v1")+"/deviceswarning", {
            devices:devs
        }).then(data=>{
            console.log('批量预警',data);
        })
    }
    const SetDevicesFree=()=>{
        let devs = [];
        devices.data.map(m=>{
            m.Sensor.map(n=>{
                devs.push({
                    sn:m.Sn,
                    node:n.Id,
                    hide:n.Hide
                })
            })
        })
        http.put((props.cloud ? "/v3" : "/v1")+"/devicesfree", {
            devices:devs
        }).then(data=>{
            console.log('批量空库',data);
        })
    }
    const SetDeviceParamsSoc = () => {
        console.log('devParams',devParams);
        if (devParams.PowerAlarmCount&&devParams.PowerAlarmInterval){
            http.get("/v1/device", {}).then(data => {
                if (data.code === 0) {
                    let devP = data.data.filter(x=>x.sn===device.Sn&&x.node===0)[0]
                    if (devP.power_alarm<2) return
                    http.put("/v1/device/" + devP.id, {
                        ...devP,
                        power_alarm:devParams.PowerAlarmInterval*60*100+devParams.PowerAlarmCount
                    }).then()
                }
            })
        }       
    }
    const getDeviceParamsSoc = () => {
        http.get("/v1/device", {}).then(data => {
            if (data.code === 0) {
                notification['success']({
                    message: '操作成功',
                    description: '',
                    duration: 2,
                });
                let devP = data.data.filter(x=>x.sn===device.Sn&&x.node===0)[0]
                setDevice({ ...device, Params: { ...device.Params, PowerAlarmMsgIntCount: Math.floor(devP.power_alarm/6000),PowerAlarmMsgCount: devP.power_alarm%100  } })
            }else{
                notification['error']({
                    message: '操作失败',
                    description: '',
                    duration: 2,
                });
            }
        })      
    }
    const SetFnc =(val='')=>{
        if(setType===sensorAlarm.type){
            device.Humi===0 &&setDevice({ ...device, Sensor: { ...device.Sensor, HumiAlarmHigh:99,HumiAlarmLow:0 } })
            if(device.Sensor.TempAlarmHigh<device.Sensor.TempAlarmLow||device.Sensor.HumiAlarmHigh<device.Sensor.HumiAlarmLow) return message.warn('参数错误,下限值应低于上限值')
            SetDeviceParams(sensorAlarm,val);
        }
        if(setType===overAlarm.type){
            SetDeviceParams(overAlarm,val);
        }
        if(setType===overAlarm.type){
            SetDeviceParams(overAlarm,val);
        }
        if(setType===batteryAlarm.type){
            SetDeviceParams(batteryAlarm,val);
        }
        if(setType===serverIP.type){
            SetDeviceParams(serverIP,val);
        }
        if(setType===deviceTime.type){
            SetDeviceParams(deviceTime,val);
        }
        if(setType===uploadInterval.type){
            SetDeviceParams(uploadInterval,val);
        }
        if(setType===sensorStop.type){
            SetDeviceParams(sensorStop,val);
        }
        if(setType===store.type){
            SetDeviceParams(store,val);
        }
        if(setType===-3){
            SetDevicesParams(val);
        }
    }

    return (
        <div>
            {
                showSgin&&<Sign getMySgin={(val)=>{
                    setShowSgin(false);
                    setTimeout(() => {
                        if(val===mySign){
                            SetFnc(val);
                        }else{
                            if(val===''){
                                // SetFnc()
                            }else{
                                Modal.error({
                                    title: '错误',
                                    centered: true,
                                    content: '电子签名不匹配',
                                });
                            }
                            
                        }
                        
                    }, 500);
                    
                }} val ={showSgin}></Sign>
            }
            <Tabs defaultActiveKey="1"  style={{fontFamily:'PingFang SC',fontWeight:'Bold',fontSize:14,color:'#333'}}
            onChange={e=>{
            }} tabBarExtraContent={<Button onClick={()=>setShowAffirm(true)} disabled={!isSet}>设置参数</Button>}>
                <TabPane tab="单测点设置" key="1">
                    <div style={{ display: 'flex',  justifyContent: 'space-between', alignItems: 'center', padding: 10 ,border:'1px solid #F2F4F8',borderRadius:5}}>
                        <span>{device.Name}</span>
                        <span>{' 主机名：' + device.MName}</span>
                        
                        <span>{' 主机编号：' + device.Sn}</span> 
                        <span>{' 测点ID: ' + device.Node}</span> 
                        <span>
                        <Button type='primary' ghost onClick={()=>{
                            let nodeLen = selDevNodes.length;
                            let index = selDevNodes.findIndex(x=>x.Sn===device.Sn&&x.Node===device.Node);
                            if(index!==-1){
                                if(index>0){
                                    setDevice(selDevNodes[index-1])
                                }else{
                                    setDevice(selDevNodes[nodeLen-1])
                                } 
                            }
                        }}>上个测点</Button>
                        <Button type='primary'  ghost style={{marginLeft:20}} onClick={()=>{
                            let nodeLen = selDevNodes.length;
                            let index = selDevNodes.findIndex(x=>x.Sn===device.Sn&&x.Node===device.Node);
                            if(index!==-1){
                                if(index<nodeLen-1){
                                    setDevice(selDevNodes[index+1])
                                }else{
                                    setDevice(selDevNodes[0])
                                } 
                            }
                        }}>下个测点</Button>
                        </span>
                        
                    </div>  
                    <Tabs defaultActiveKey="1" style={{border:'1px solid #F2F4F8',borderRadius:5,marginTop:15,paddingLeft:10,paddingRight:10}}>
                        <TabPane tab="报警设置" key="1">
                            <div style={{height:'500px'}}>
                                <Spin spinning={load}>
                                <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                                    {/* 温湿度预警 */}
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center',backgroundColor:'#F2F4F8',paddingLeft:10,paddingRight:10}}>
                                        <div style={{color:'#2569D8'}}>预警参数：</div>
                                        {/* <Space> */}
                                        <Input.Group style={{ width: 1000 }} compact>
                                            <Input defaultValue={device.Sensor.TempWarningHigh} addonBefore={"温度上限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^-?\d+(\.\d*)?$/
                                                if (reg.test(e.target.value)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningHigh: parseFloat(e.target.value) } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningHigh: 0 } })
                                                }
                                            }} />
                                            <Input defaultValue={device.Sensor.TempWarningLow} addonBefore={"温度下限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningLow: parseFloat(e.target.value) } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningLow: 0 } })
                                                }
                                            }} />
                                            <Input defaultValue={device.Sensor.HumiWarningHigh} addonBefore={"湿度上限"} addonAfter={"%RH"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiWarningHigh: parseFloat(e.target.value) } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiWarningHigh: 0 } })
                                                }
                                            }}  disabled={device.Humi===0}/>
                                            <Input defaultValue={device.Sensor.HumiWarningLow} addonBefore={"湿度下限"} addonAfter={"%RH"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiWarningLow: parseFloat(e.target.value) } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiWarningLow: 0 } })
                                                }
                                            }} disabled={device.Humi===0}/>
                                            <Input defaultValue={device.Sensor.WarningInterval} addonBefore={"报警间隔"} addonAfter={"分"} style={{ width: 200, margin: 5 }} maxLength={3}
                                            onChange={e => {
                                                var reg = intReg
                                                let val = e.target.value===''?1:e.target.value;
                                                if (reg.test(val)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, WarningInterval: val*1 } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, WarningInterval: 2 } })
                                                }
                                            }} />
                                            {/* <div style={{width:200 ,display:'flex',justifyContent:'space-between'}}> 
                                                <div >温度预警正常</div>
                                                <div >湿度预警正常</div>
                                            </div> */}
                                            {/* <span style={{width:100,paddingTop:10,marginLeft:5}}>温度预警正常</span>
                                            <span style={{width:100,paddingTop:10,textAlign:'end'}}>湿度预警正常</span> */}
                                        </Input.Group>

                                        {/* </Space> */}
                                        <div style={{ flex: 1 }}></div>
                                        <Space>
                                            {
                                                setState>0&&
                                                <Button type='primary'  ghost onClick={()=>{
                                                    setLoad(true);
                                                    http.put("/v1/devicewarning/" + device.ID, { 
                                                        temp_waning_high: device.Sensor.TempWarningHigh, 
                                                        temp_waning_low:device.Sensor.TempWarningLow,
                                                        humi_waning_high:device.Sensor.HumiWarningHigh,
                                                        humi_waning_low:device.Sensor.HumiWarningLow,
                                                        warning_interval:device.Sensor.WarningInterval
                                                        }).then(data=>{
                                                            setLoad(false);
                                                            if(data.code===0){
                                                                setDevice({
                                                                    ...device,
                                                                    TempWarningHigh:device.Sensor.TempWarningHigh,
                                                                    TempWarningLow:device.Sensor.TempWarningLow,
                                                                    HumiWarningHigh:device.Sensor.HumiWarningHigh,
                                                                    HumiWarningLow:device.Sensor.HumiWarningLow,
                                                                    WarningInterval:device.Sensor.WarningInterval
                                                                })
                                                            }
                                                        })
                                                }} >设置</Button>
                                            }
                                            
                                        </Space>
                                    </Col>
                                    {/* <Divider style={{ margin: '1px 0' }} /> */}
                                    {/* 温湿度报警 */}
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center',paddingLeft:10,paddingRight:10 }}>
                                        <div style={{color:'#2569D8'}}>报警参数：</div>
                                        {/* <Space> */}
                                        <Input.Group style={{ width: 1000 }} compact>
                                            <Input value={device.Sensor.TempAlarmHigh} addonBefore={"温度上限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                let reg = /^-?\d+(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    sensorAlarm.error = false
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempAlarmHigh: parseFloat(e.target.value) } })
                                                } else {
                                                    message.error('输入数据不合规');
                                                    sensorAlarm.error = true
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempAlarmHigh: (e.target.value) } })
                                                }
                                            }} />
                                            <Input value={device.Sensor.TempAlarmLow} addonBefore={"温度下限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^-?\d+(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    sensorAlarm.error = false
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempAlarmLow: parseFloat(e.target.value) } })
                                                } else {
                                                    message.error('输入数据不合规');
                                                    sensorAlarm.error = true
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempAlarmLow: (e.target.value) } })
                                                }
                                            }} />
                                            <Input value={device.Sensor.HumiAlarmHigh} addonBefore={"湿度上限"} addonAfter={"%RH"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    sensorAlarm.error = false
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiAlarmHigh: parseFloat(e.target.value) } })
                                                } else {
                                                    sensorAlarm.error = true
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiAlarmHigh: (e.target.value) } })
                                                }
                                            }} disabled={device.Humi===0}/>
                                            <Input value={device.Sensor.HumiAlarmLow} addonBefore={"湿度下限"} addonAfter={"%RH"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    sensorAlarm.error = false
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiAlarmLow: parseFloat(e.target.value) } })
                                                } else {
                                                    sensorAlarm.error = true
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiAlarmLow: (e.target.value) } })
                                                }
                                            }} disabled={device.Humi===0}/>
                                        </Input.Group>

                                        {/* </Space> */}
                                        <div style={{ flex: 1 }}></div>
                                        <Space>
                                            <Button type='primary'  ghost onClick={() => {ReadDeviceParams(sensorAlarm, setSensorAlarm);}}>读取</Button>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    setSetType(sensorAlarm.type)
                                                    if(signEnable){
                                                        setShowSgin(true)
                                                    }else{
                                                        SetFnc();
                                                    }
                                                   
                                                    
                                                }} disabled={Mode!=='soc'&&state.main.type === 104} type='primary'  ghost>设置</Button>
                                            }
                                            
                                        </Space>
                                    </Col>
                                    {/* <Divider style={{ margin: '1px 0' }} /> */}

                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' ,backgroundColor:'#F2F4F8',paddingLeft:10,paddingRight:10 }}>
                                        <div style={{color:'#2569D8'}}>断电报警：</div>
                                        <Input.Group style={{ width: 800 }} compact>
                                            <Input value={device.Params.PowerAlarmTimeCount} addonBefore={"断电时间"} addonAfter={"秒"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg =  intReg
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    powerAlarm.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, PowerAlarmTimeCount: Number(val) } })
                                                } else {
                                                    powerAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, PowerAlarmTimeCount: val } })
                                                }
                                            }} />
                                            <Input value={device.Params.PowerAlarmMsgIntCount} addonBefore={"报警间隔"} addonAfter={"分"} style={{ width: 200, margin: 5 }} maxLength={3} onChange={e => {
                                                var reg = intReg
                                                let val = e.target.value===''?1:e.target.value;
                                                if (reg.test(val)) {
                                                    powerAlarm.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, PowerAlarmMsgIntCount: Number(val) } })
                                                    
                                                    devParams.PowerAlarmInterval?setDevParams({...devParams,PowerAlarmInterval:Number(val)}):addEle('PowerAlarmInterval',Number(val))
                                                } else {
                                                    powerAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, PowerAlarmMsgIntCount: (val) } })
                                                }
                                            }} />
                                            <Input value={device.Params.PowerAlarmMsgCount} addonBefore={"报警次数"} addonAfter={"次"} maxLength={3} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg =  intReg
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    powerAlarm.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, PowerAlarmMsgCount: parseFloat(val) } })
                                                    devParams.PowerAlarmCount?setDevParams({...devParams,PowerAlarmCount:Number(val)}):addEle('PowerAlarmCount',Number(val))
                                                } else {
                                                    powerAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, PowerAlarmMsgCount: (val) } })
                                                }
                                            }} />
                                        </Input.Group>
                                        <div style={{ flex: 1 }}></div>
                                        <Space>
                                            <Button type='primary'  ghost onClick={() => {
                                                ReadDeviceParams(powerAlarm, setPowerAlarm);
                                            }}>读取</Button>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    let devSel= state.device.filter(x=>x.sn===device.Sn&&x.node===0);
                                                    if(devSel.length>0&&devSel[0].power_alarm>1){
                                                        SetDeviceParamsSoc();
                                                    }
                                                    SetDeviceParams(powerAlarm);
                                                }} disabled={Mode!=='soc'&&state.main.type === 104} type='primary'  ghost>设置</Button>
                                            }
                                        </Space>
                                    </Col>
                                    {/* <Divider style={{ margin: '1px 0' }} /> */}
                                    {/* 超标报警 */}
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center',paddingLeft:10,paddingRight:10 }}>
                                        <div style={{color:'#2569D8'}}>超标报警：</div>
                                        <Input.Group style={{ width: 800 }} compact>
                                            <Input value={device.Params.OverAlarm.StartTime} addonBefore={"开始时间"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^\d{2}\:\d{2}$/
                                                if (reg.test(e.target.value)) {
                                                    overAlarm.error = false
                                                } else {
                                                    overAlarm.error = true
                                                }
                                                setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, StartTime: (e.target.value) } } })
                                            }} />
                                            <Input value={device.Params.OverAlarm.EndTime} addonBefore={"结束时间"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^\d{2}\:\d{2}$/
                                                if (reg.test(e.target.value)) {
                                                    overAlarm.error = false
                                                } else {
                                                    overAlarm.error = true
                                                }
                                                setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, EndTime: (e.target.value) } } })
                                            }} />
                                            <Input value={device.Params.OverAlarm.Time} addonBefore={"超标时间"} addonAfter={"秒"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = intReg
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    overAlarm.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, Time: Number(val) } } })
                                                } else {
                                                    overAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, Time:val } } })
                                                }
                                            }} />
                                            <Input value={device.Params.OverAlarm.TimeInt} addonBefore={"报警间隔"} addonAfter={"分"} style={{ width: 200, margin: 5 }} maxLength={3} onChange={e => {
                                                var reg = intReg
                                                let val = e.target.value===''?1:e.target.value;
                                                if (reg.test(val)) {
                                                    overAlarm.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, TimeInt: Number(val) } } })
                                                } else {
                                                    overAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, TimeInt: val } } })
                                                }
                                            }} />
                                            <Input value={device.Params.OverAlarm.TempCount} addonBefore={"温度次数"} addonAfter={"次"} style={{ width: 200, margin: 5 }} maxLength={3} onChange={e => {
                                                var reg = intReg
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    overAlarm.error = false
                                                    let count =Number(val);
                                                    if(count>255){
                                                        count=255
                                                    }
                                                    setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, TempCount: count} } })
                                                } else {
                                                    overAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, TempCount: val } } })
                                                }
                                            }} />
                                            <Input value={device.Params.OverAlarm.HumiCount} addonBefore={"湿度次数"} addonAfter={"次"} maxLength={3} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = intReg
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    overAlarm.error = false
                                                    let count =Number(val);
                                                    if(count>255){
                                                        count=255
                                                    }
                                                    setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, HumiCount: count } } })
                                                } else {
                                                    overAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, HumiCount: val } } })
                                                }
                                            }} />
                                        </Input.Group>
                                        <div style={{ flex: 1 }}></div>
                                        <Space>
                                            <Button onClick={() => {ReadDeviceParams(overAlarm, setOverAlarm)}} type='primary'  ghost>读取</Button>
                                            {
                                                setState>0&&
                                                <Button onClick={() =>{ 
                                                    setSetType(overAlarm.type)
                                                    if(signEnable){
                                                        setShowSgin(true)
                                                    }else{
                                                        SetFnc();
                                                    }
                                                   }} disabled={Mode!=='soc'&&state.main.type === 104} type='primary'  ghost>设置</Button>
                                            }
                                        </Space>
                                    </Col>
                                    {/* <Divider style={{ margin: '1px 0' }} /> */}
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' ,backgroundColor:'#F2F4F8',paddingLeft:10,paddingRight:10}}>
                                        <div style={{color:'#2569D8'}}>电量报警：</div>
                                        <Input.Group style={{ width: 420 }} compact>
                                            <Input value={device.Params.BatPowerAlarm} addonBefore={"主机电量"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg =  intReg
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    batteryAlarm.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, BatPowerAlarm: Number(val) } })
                                                } else {
                                                    batteryAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, BatPowerAlarm: val } })
                                                }
                                            }} />
                                            <Input value={device.Sensor.BatPowerAlarm} addonBefore={"探头电量"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = intReg
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    batteryAlarm.error = false
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, BatPowerAlarm: Number(val) } })
                                                } else {
                                                    batteryAlarm.error = true
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, BatPowerAlarm: (val) } })
                                                }
                                            }} />
                                        </Input.Group>
                                        <div style={{ flex: 1 }}></div>
                                        <Space>
                                            <Button onClick={() => {ReadDeviceParams(batteryAlarm, setBatteryAlarm);}}type='primary'  ghost>读取</Button>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    setSetType(batteryAlarm.type)
                                                    if(signEnable){
                                                        setShowSgin(true)
                                                    }else{
                                                        SetFnc();
                                                    }}} disabled={Mode!=='soc'&&state.main.type === 104}type='primary'  ghost>设置</Button>
                                            }
                                        </Space>
                                    </Col>
                                    
                                    {/* 离线报警 */}
                                    <Col span={24} style={{display:'flex',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                                        <div style={{color:'#2569D8'}}>离线报警：</div>
                                        <Input.Group style={{ width: 800 }} compact>
                                            <Input defaultValue={device.Sensor.OffLineTime}  addonBefore={"离线间隔"} addonAfter={'分'} style={{width:200,margin:5}} autoComplete='off'
                                            onChange={e=>{
                                                setOffLine({...offline,offtime:e.target.value*1})
                                            }}/>
                                            <Input defaultValue={device.Sensor.OffLineAlarmInterval}  addonBefore={"报警间隔"} addonAfter={'分'} maxLength={3} style={{width:200,margin:5}} autoComplete='off'
                                            onChange={e=>{
                                                let reg = intReg
                                                let val = e.target.value===''?1:e.target.value;
                                                if(reg.test(val)){
                                                    setOffLine({...offline,interval:val*1})
                                                }else{
                                                    setOffLine({...offline,interval:1})
                                                }
                                                
                                            }}/>
                                            <Input defaultValue={device.Sensor.OffLineAlarmCount}  addonBefore={"报警次数"} addonAfter={'次'} style={{width:200,margin:5}} maxLength={3} autoComplete='off'
                                            onChange={e=>{
                                                setOffLine({...offline,count:e.target.value*1})
                                            }}/>
                                        </Input.Group>
                                        <div style={{ flex: 1 }}></div>
                                        <Button onClick={() => {
                                            SetDeviceOffLineAlarm({...offline,alarm:device.Sensor.OffLineAlarm === 0 ?1:0});
                                        }}type='primary'  ghost>{device.Sensor.OffLineAlarm === 0 ? "已关闭" : "已打开"}</Button>
                                        
                                    </Col>
                                    {/* <Divider style={{ margin: '1px 0' }} /> */}
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' ,paddingLeft:10,paddingRight:10}}>
                                        <div style={{color:'#2569D8'}}>原始命令：</div>
                                        <Input.Group style={{ width: 600 }} compact>
                                            <Input value={cmd}  style={{ width: 500, margin: 5 }} onChange={e => {
                                                setCmd(e.target.value)
                                            }} />
                                        </Input.Group>
                                        <div style={{ flex: 1 }}></div>
                                        <Space>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    http.post((props.cloud ? "/v3" : "/v1") + "/senddevice", {device: device.Sn,msg:cmd }).then(data => {
                                                        console.log(data);
                                                        // setLoad(false)
                                                    })
                                                }} disabled={Mode!=='soc'&&state.main.type === 104} type='primary'  ghost>设置</Button>
                                            }
                                        </Space>
                                    </Col>
                                </Row>
                            </Spin>
                            </div>
                        </TabPane>
                        <TabPane tab="基本设置" key="2">
                            <div style={{height:'500px'}}>
                                <Spin spinning={load}>
                                <Row gutter={[10, 20]} style={{ position: 'relative' }}>
                                    {/* <Divider orientation="left">基本设置</Divider> */}
                                    {/* IP地址 */}
                                    <Col span={12} style={{ display: 'flex'}}>
                                        <Input style={{width:260}} value={device.Params.IPAddress} addonBefore={"网络地址"} placeholder='xxx.xxx.xxx.xxx:5566' onChange={e => {
                                            setDevice({ ...device, Params: { ...device.Params, IPAddress: e.target.value } })
                                        }} />
                                            
                                        <Space style={{marginLeft:130}}>
                                            <Button onClick={() => {
                                                    ReadDeviceParams(serverIP, setServerIP);
                                                }}>读取</Button>
                                            {
                                                setState>0&&state.main.type<103&&
                                                <Button onClick={() =>{
                                                    setSetType(serverIP.type)
                                                    if(signEnable){
                                                        setShowSgin(true)
                                                    }else{
                                                        SetFnc();
                                                    } }} disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                            
                                        </Space>
                                    </Col>
                                    
                                    {/* 远程关机 */}
                                    <Col span={12} style={{ display: 'flex' }}>
                                        <div style={{flex:1}}></div>
                                        <Input style={{width:260}} addonBefore={"设备操作"} disabled value={"请谨慎操作"} />
                                        <Space style={{marginLeft:190}}>
                                            <Button onClick={()=>{
                                                setDevClose({...device, Params: {...device.Params}});
                                                SetDeviceParams(devClose)}} disabled={Mode!=='soc'&& state.main.type === 104}>关机</Button>
                                            {/* <Button>重启</Button> */}
                                        </Space>
                                    </Col>
                                    {/* 读取设备时间 */}
                                    <Col span={12} style={{ display: 'flex'}}>
                                        <Input style={{width:260}} value={device.Params.DeviceTime} addonBefore={"设备时间"} disabled />
                                        <Space style={{marginLeft:130}}>
                                            <Button onClick={() => {ReadDeviceParams(deviceTime, setDeviceTime);}}>读取</Button>
                                            {
                                                setState>0&&
                                                <Button onClick={() =>{
                                                    setSetType(deviceTime.type)
                                                    if(signEnable){
                                                        setShowSgin(true)
                                                    }else{
                                                        SetFnc();
                                                    }} } disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                        </Space>
                                    </Col>
                                    {/* 空库设置 */}
                                    <Col span={12} style={{ display: 'flex', alignItems: 'center',color:'#666'}}>
                                        <div style={{flex:1}}></div>
                                        <div style={{border: '1px solid #ccc', width: 80, margin: 0, padding: '5px 8px', borderRightColor: 'transparent'  }}>空库设置</div>
                                        <div style={{border: '1px solid #ccc', width: 180, margin: 0, padding: '5px 10px' }}>
                                            <Checkbox checked={device.Sensor.Hide>0}  onChange={e=>{
                                                // setDevHide(2);
                                                // let devsTemp = JSON.parse(JSON.stringify(devices));
                                                //                             setDevices(devsTemp)
                                                setDevice({ ...device, Sensor: { ...device.Sensor, Hide: e.target.checked?2:0 } })

                                            }}>空库</Checkbox>
                                            <Checkbox checked={device.Sensor.Hide===2} onChange={e=>{
                                                // setDevHide(e.target.checked?2:3);
                                                setDevice({ ...device, Sensor: { ...device.Sensor, Hide: e.target.checked?2:3 } })
                                            }}>存储</Checkbox>
                                        </div>
                                        {
                                            // setState>0&&
                                            <Button style={{marginLeft:190}} onClick={()=>{
                                                http.put((props.cloud ? "/v3" : "/v1") + "/devicefree/" + device.ID, {
                                                    sn:device.Sn,
                                                    node:device.Node,
                                                    hide:device.Sensor.Hide
                                                }).then(data=>{
                                                    console.log('空库返回',data);
                                                })
                                            }} disabled={setState<1}>设置</Button> 
                                        }
                                        
                                    </Col>
                                    {/* 回传间隔 */}
                                    <Col span={12} style={{ display: 'flex' }}>
                                        
                                        <Input style={{width:260}} value={device.Params.RealTimeInt} addonBefore={"回传间隔"} addonAfter={"秒"} onChange={e => {
                                            var reg =  /^([2-9]\d|[1-9][\d]{2,4})$/
                                            let val = e.target.value===''?20:e.target.value;
                                            if (reg.test(val)) {
                                                uploadInterval.error = false

                                                setDevice({ ...device, Params: { ...device.Params, RealTimeInt: Number(val) } })
                                            } else {
                                                message.error('输入大于20的数字');
                                                uploadInterval.error = true
                                                setDevice({ ...device, Params: { ...device.Params, RealTimeInt: val } })
                                            }
                                        }} />
                                        <Space style={{marginLeft:130}}>
                                            <Button onClick={() => {ReadDeviceParams(uploadInterval, setUploadInterval);}}>读取</Button>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    setSetType(uploadInterval.type)
                                                    if(signEnable){
                                                        setShowSgin(true)
                                                    }else{
                                                        SetFnc();
                                                    }}} disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                        </Space>
                                    </Col>
                                    {/* 探头连接失败 */}
                                    <Col span={12} style={{ display: 'flex', alignItems: 'center'}} >
                                        <div style={{flex:1}}></div>
                                        <Input style={{width:260}} addonBefore={"探头报警"} disabled value={device.Sensor.LinkAlarm === 0 ?"探头连接失败已关闭" : "探头连接失败已打开"} />
                                        {/* <div style={{border: '1px solid #ccc', width: 80, margin: 0, padding: '5px 10px', borderRightColor: 'transparent'  }}>探头报警</div> */}
                                        {/* <div style={{border: '1px solid #ccc', width: 180, margin: 0, padding: '5px 20px' }}>探头连接失败</div> */}
                                        
                                        {
                                            // setState>0&&
                                            <Button style={{marginLeft:190}} disabled={setState<1} onClick={() => {
                                                SetDeviceLinkAlarm(device.Sensor.LinkAlarm === 0 ? 1 : 0);
                                            }}>{device.Sensor.LinkAlarm === 0 ? "打开" : "关闭"}</Button>
                                        }
                                    </Col>
                                    
                                    {/* 探头停用 */}
                                    <Col span={12} style={{ display: 'flex', alignItems: 'center', color:'#666'}}>
                                        <div style={{border: '1px solid #ccc', width: 80, margin: 0, padding: '5px 10px', borderRightColor: 'transparent'  }}>探头停用</div>
                                        <div style={{border: '1px solid #ccc', width: 180, margin: 0, padding: '5px 20px' }}>
                                            <Checkbox checked={device.Sensor.Enable == 1 ? true : false} onChange={e => {
                                                setDevice({ ...device, Sensor: { ...device.Sensor, Enable: e.target.checked ? 1 : 0 } })
                                            }}>停用</Checkbox>
                                        </div>
                                        <Space style={{marginLeft:130}}>
                                            <Button onClick={() => {ReadDeviceParams(sensorStop, setSensorStop);}}>读取</Button>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    setSetType(sensorStop.type)
                                                    if(signEnable){
                                                        setShowSgin(true)
                                                    }else{
                                                        SetFnc();
                                                    }}} disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                        </Space>
                                        {/* <div style={{flex:1}}></div> */}
                                    </Col>
                                    
                                    
                                    {/* 读取历史数据 */}
                                    <Col span={12} style={{ display: 'flex' }}>
                                    <div style={{flex:1}}></div>
                                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                                            <span style={{ border: '1px solid #ccc', display: 'inline-block', width: 80, margin: 0, padding: '5px 10px', borderRightColor: 'transparent',color:'#666',backgroundColor:'#fafafa' }}>读取数据</span>
                                            <RangePicker style={{ margin: 0,width:330}}
                                                ranges={{
                                                    今天: [moment().startOf('day'), moment()],
                                                    '本周': [moment().startOf('week'), moment().endOf('week')],
                                                    '本月': [moment().startOf('month'), moment().endOf('month')],
                                                }}
                                                showTime
                                                format="YYYY-MM-DD HH:mm"
                                                onChange={(dates, dateStrings) => {
                                                    // console.log(dateStrings)
                                                    if (dateStrings[0] !== "") {
                                                        setTimeReadDataStart({ validity: true, error: false, value:dateStrings[0] })
                                                        setTimeReadDataEnd({ validity: true, error: false, value:dateStrings[1] })
                                                    }
                                                }}
                                            />
                                        </div>
                                            
                                        <Space style={{marginLeft:40}}>
                                            <Button onClick={() => {ReadDeviceData();}}>读取</Button>
                                            {/* <Button onClick={() => {ReadDeviceDataInt();}}>间隔读取</Button> */}
                                        </Space>
                                    </Col>
                                    
                                    {/* 存储间隔 */}
                                    <Col span={12} style={{ display: 'flex' }}>
                                        
                                        <Input style={{width:170}} value={device.Params.NorStoreInt} addonBefore={"正常间隔"} addonAfter={"分"} onChange={e => {
                                            var reg =  intReg
                                            let val = e.target.value===''?0:e.target.value;
                                            if (reg.test(val)) {
                                                store.error = false
                                                setDevice({ ...device, Params: { ...device.Params, NorStoreInt: Number(val) } })
                                            } else {
                                                store.error = true
                                                setDevice({ ...device, Params: { ...device.Params, NorStoreInt: val } })
                                            }
                                        }} />
                                        <Input style={{width:170,marginLeft:10}} value={device.Params.AlmStoreInt} addonBefore={"报警间隔"} addonAfter={"分"} disabled={setState<1} onChange={e => {

                                            var reg = intReg
                                            let val = e.target.value===''?0:e.target.value;
                                            if (reg.test(val)) {
                                                store.error = false
                                                setDevice({ ...device, Params: { ...device.Params, AlmStoreInt: Number(val) } })
                                            } else {
                                                store.error = true
                                                setDevice({ ...device, Params: { ...device.Params, AlmStoreInt: val } })
                                            }
                                        }}/>
                                        
                                        <Space style={{marginLeft:40}}>
                                            <Button onClick={() => {ReadDeviceParams(store, setStore);}}>读取</Button>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    setSetType(store.type)
                                                    if(signEnable){
                                                        setShowSgin(true)
                                                    }else{
                                                        SetFnc();
                                                    }}} disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                        </Space>
                                        <div style={{flex:1}}></div>
                                    </Col>

                                    {
                                        // 补偿
                                    setState>1&&
                                        <Col span={12} style={{ display: 'flex',color: '#666' }}>
                                            <div style={{flex:1}}></div>
                                            <Input value={device.Sensor.TempCompensate} addonBefore={"温度补偿"}  style={{ width: 160 }} onChange={e => {
                                                let reg = /^-?(([01]{1}(\.\d+)?)|([2]{1}(\.[0-5]{1})?))$/
                                                let errMsg = '输入-2.5至2.5之间的一位小数的数字'
                                                if(Mode==='soc'){
                                                    if(state.main.type<102||setState>2){
                                                        reg =/^-?(([1]?\d{1}(\.\d{1})?)|([2]{1}[0-4]{1}(\.\d{1})?)|([2]{1}[5]{1}))$/
                                                        errMsg = '输入-25至25之间的一位小数的数字'
                                                    }
                                                }else{
                                                    if(state.main.type<102&&isStrong){
                                                        reg =/^-?(([1]?\d{1}(\.\d{1})?)|([2]{1}[0-4]{1}(\.\d{1})?)|([2]{1}[5]{1}))$/
                                                        errMsg = '输入-25至25之间的一位小数的数字'
                                                    }
                                                }
                                                if (reg.test(e.target.value)) {
                                                    revise.error = false
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempCompensate: parseFloat(e.target.value) } })
                                                } else {
                                                    message.error(errMsg);
                                                    revise.error = true
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempCompensate: (e.target.value) } })
                                                }
                                            }} />
                                            <Input value={device.Sensor.HumiCompensate} addonBefore={"湿度补偿"}  style={{ width: 160}} onChange={e => {
                                                let reg = /^-?(([1]?\d{1}(\.\d{1})?)|([2]{1}[0-4]{1}(\.\d{1})?)|([2]{1}[5]{1}))$/
                                                if (reg.test(e.target.value)) {
                                                    revise.error = false
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiCompensate: parseFloat(e.target.value) } })
                                                } else {
                                                    message.error('输入-25至25之间的一位小数的数字');
                                                    revise.error = true
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiCompensate: (e.target.value) } })
                                                }
                                            }} />
                                           {/* <div style={{border: '1px solid #ccc', width: 80, margin: 0, padding: '5px 10px', borderRightColor: 'transparent'}}>温度补偿</div>
                                            <InputNumber style={{width:80,margin:0}}  value = {device.Sensor.TempCompensate} placeholder='0' onChange={e => {
                                                let value = e;
                                                let reg = /^-?(([01]{1}(\.\d*)?)|([2]{1}(\.[0-5]*)?))$/
                                                if(reg.test(value)){
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempCompensate: value } })
                                                }else{
                                                    message.error('输入-2.5至2.5之间的数字');
                                                }
                                                
                                            }} /> */}
                                            {/* <div style={{border: '1px solid #ccc', width: 80, margin: 0, padding: '5px 10px', borderRightColor: 'transparent'}}>湿度补偿</div>
                                            <InputNumber style={{width:80}} value={device.Sensor.HumiCompensate}  placeholder='0' onChange={e => {
                                                setDevice({ ...device, Sensor: { ...device.Sensor, HumiCompensate:e } })
                                            }}  /> */}
                                            <Space style={{marginLeft:60}}>
                                                <Button onClick={() => {ReadDeviceParams(revise, setRevise);}}>读取</Button>
                                                {
                                                    setState>0&&
                                                    <Button onClick={() =>{ SetDeviceParams(revise); }}  >设置</Button>
                                                }
                                                
                                            </Space>
                                        </Col>
                                    }
                                    {/* 存储信息 */}
                                    {
                                        setState>1&&
                                        <Col span={24} style={{ display: 'flex',alignItems:'center' }}>
                                        <div  style={{border: '1px solid #ccc', width: 80, margin: 0, padding: '5px 10px' }}>存储信息</div>
                                        <div style={{marginLeft:10}}>当前扇区:{device.StoreInfo.CurrSector}</div>
                                        <div style={{marginLeft:50}}>当前ADDRESS:{device.StoreInfo.CurrSectorAddr}</div>
                                        <div style={{marginLeft:50}}>读取扇区:{device.StoreInfo.ReadSector}</div>
                                        <div style={{marginLeft:50}}>读取ADDRESS:{device.StoreInfo.ReadSectorAddr}</div>
                                        <div style={{marginLeft:50}}>大批量读取数据的起始扇区:{device.StoreInfo.LargeSector}</div>
                                        <div style={{flex:1}}></div>
                                        <Button onClick={() => {ReadDeviceParams(devStronInfo, setDevStronInfo);}}>读取</Button>
                                        <Button style={{marginLeft:10}} onClick={() => {
                                            Modal.confirm({
                                                centered: true,
                                                title: `确定要清除设备存储信息吗？`,
                                                icon: <ExclamationCircleOutlined />,
                                                content: `设备${device.Sn}的存储信息清除之后将不可恢复！！`,
                                                okText: '清除',
                                                okType: 'danger',
                                                cancelText: '取消',
                                                onOk() {
                                                    SetDeviceParams(devStronInfo, setDevStronInfo);
                                                },
                                                onCancel() {
                                                    console.log('Cancel');
                                                },
                                            });
                                            
                                            }}>清除</Button>
                                        

                                    </Col>
                                    }

                                    {
                                        setState>1&&state.main.type<104&&
                                        <Col span={24} style={{ display: 'flex',alignItems:'center' }}>
                                        <Input.Password style={{width:260}} value={npw1} addonBefore={"输入新密码"} onChange={e => {
                                            let ss = e.target.value;
                                            setNpw1(ss);
                                        }} />
                                        <Input.Password style={{width:260}} value={npw2} addonBefore={"重输新密码"} onChange={e => {
                                            let ss = e.target.value;
                                            setNpw2(ss);
                                             (ss==""||ss !== npw1 )&& notification['error']({
                                                message: '两次输入密码不正确',
                                                duration: 2,
                                            });
                                        }} />
                                     
                                        <Button style={{marginLeft:10}} onClick={() => {
                                            if(npw2==""||npw2 !== npw1 ) return notification['error']({
                                                message: '两次输入密码不正确',
                                                duration: 2,
                                            });
                                            let diss = state.district.filter(x=>x.user==state.main.id);
                                            if(!diss||diss.length==0) return notification['error']({
                                                message: '账号下没有直属区域',
                                                duration: 2,
                                            });
                                            let devs=[]
                                            diss.map(dis=>{
                                                state.device.map(m=>{
                                                    if(m.district ==dis.id&&!devs.includes(m.sn)){
                                                        devs.push(m.sn)
                                                    }
                                                })
                                            })
                                            http.put('/v1/devicespassword',{
                                                devices:devs,
                                                password:npw2
                                            })
                                            }}>修改</Button>
                                        

                                    </Col>
                                    }
                                    
                                </Row>
                            </Spin>
                            </div>
                        </TabPane>
                    </Tabs>
                </TabPane>
                {
                    // state.main.type===101&&
                    <TabPane tab="多测点设置" key="2">
                        <div style={{ overflow:'scroll' ,maxHeight:230}} className='setFont'> 
                            <div className='setItemBack'>
                                <Checkbox  indeterminate={(devices.data.length===0||devList.filter(x=>x.all).length===devList.length)?false:true} checked={devList.filter(x=>x.all).length===devList.length}
                                onChange={e=>{
                                    let devInfo= devices;
                                    if(e.target.checked){
                                        devList.map(m=>{
                                            m.all = true;
                                            m.indeterminate = false
                                            m.nodes.map(mn=>{
                                                mn.checked = true
                                            })
                                        })
                                        let devsTemp = 
                                        state.main.type<102?  state_device.devicestate.filter(x=>x.Node!==0&&x.District===props.item.District&&
                                        (x.Type!==205&&x.Type!==207&&x.Type!==209))
                                        : 
                                        state_device.devicestate.filter(x=>x.Node!==0&&x.District===props.item.District&&(Mode==='soc'?x.ExprieStatus!==3:true)&&
                                        (x.Type!==205&&x.Type!==207&&x.Type!==209));
                                        let devsData=[]
                                        devsTemp.map(m=>{
                                            let temp =devsData.findIndex(x=>x.Sn===m.Sn);
                                            m.Sensor.Name = m.Name
                                            if(temp===-1){
                                                devsData.push({Sn:m.Sn,Params:m.Params,Sensor:[m.Sensor],StoreInfo:m.StoreInfo,Type:m.Type,MName:m.MName})
                                            }else{
                                                devsData[temp].Sensor.push(m.Sensor)
                                            }
                                        })
                                        devsData.sort((a,b)=>a.Sn-b.Sn)
                                        devInfo.data=devsData;
                                    }else{
                                        devList.map(m=>{
                                            m.all = false;
                                            m.nodes.map(mn=>{
                                                mn.checked = false
                                            })
                                        })
                                        devInfo.data =[]
                                    }
                                    let devs = devList.concat();
                                    setDevList(devs)
                                    setDevices(devInfo)
                                }}>
                                    全选
                                </Checkbox>
                            </div>
                            {
                                devList.map((m,index)=>{
                                    return(
                                    <div style={{display:'flex',flexDirection:'row',justifyItems:'center'}} key={index} className={index%2===0?'setItemBack1':'setItemBack'}>
                                        <div style={{whiteSpace:'nowrap'}}>
                                            <span style={{ marginLeft: '10px',color:'#999' }}>主机名: </span> 
                                            <span>{m.name}</span>
                                        </div>
                                        <div style={{whiteSpace:'nowrap',marginLeft: '10px'}}>
                                            <span style={{ color:'#999'}}>主机编号: </span> 
                                            <span>{m.sn}</span>
                                        </div>
                                        
                                        <div style={{whiteSpace:'nowrap',marginLeft: 10}}>
                                            <span margin-left='14px' style={{ color:'#999' }}>测点 </span>
                                            <Checkbox indeterminate={m.indeterminate} 
                                            onChange={e=>{
                                                let devInfo= devices;
                                                let tempdevInfo = state.main.type<102?  state_device.devicestate.filter(x=>x.Node!==0&&x.District===props.item.District&&
                                                    (x.Type!==205&&x.Type!==207&&x.Type!==209))
                                                    : 
                                                    state_device.devicestate.filter(x=>x.Node!==0&&x.District===props.item.District&&(Mode==='soc'?x.ExprieStatus!==3:true)&&
                                                    (x.Type!==205&&x.Type!==207&&x.Type!==209));
                                                m.indeterminate = false
                                                if(e.target.checked){
                                                    m.nodes.map(n=>{
                                                        n.checked = true;
                                                    })
                                                    m.all = true;
                                                    tempdevInfo.filter(x=>x.Sn===m.sn).map(n=>{
                                                        let temp = devInfo.data.findIndex(x=>x.Sn===m.sn);
                                                        n.Sensor.Name = n.Name
                                                        if(temp===-1){
                                                            devInfo.data.push({Sn:n.Sn,Params:n.Params,Sensor:[n.Sensor],StoreInfo:n.StoreInfo,Type:n.Type,MName:n.MName})
                                                        }else{
                                                            devInfo.data[temp].Sensor.push(n.Sensor)
                                                        }
                                                    })
                                                    devInfo.data.sort((a,b)=>a.Sn-b.Sn)
                                                }else{
                                                    m.all = false;
                                                    m.nodes.map(n=>{
                                                        n.checked = false
                                                    })
                                                    devInfo.data = devInfo.data.filter(x=>x.Sn!==m.sn)
                                                }
                                                let templist = devList.concat()
                                                setDevList(templist)
                                                setDevices(devInfo)
                                            }} checked={m.all}>
                                                全选
                                            </Checkbox>
                                        </div>
                                        <div style={{whiteSpace:'nowrap'}}>
                                        {
                                            m.nodes.map(mm=>{
                                                return(
                                                    <Checkbox value={mm.value} checked={mm.checked} key={m.sn+''+mm.value}
                                                    onChange={e=>{
                                                        let devInfo= devices;
                                                        let temp = state_device.devicestate.filter(x=>x.ID === mm.value);
                                                        let n = temp[0];
                                                        let index = devInfo.data.findIndex(x=>x.Sn===n.Sn)
                                                        if(e.target.checked){
                                                            mm.checked = true;
                                                            n.Sensor.Name = n.Name
                                                            if(index===-1){
                                                                devInfo.data.push({Sn:n.Sn,Params:n.Params,Sensor:[n.Sensor],StoreInfo:n.StoreInfo,Type:n.Type,MName:n.MName})
                                                                devInfo.data.sort((a,b)=>a.Sn-b.Sn)
                                                            }else{
                                                                devInfo.data[index].Sensor.push(n.Sensor);
                                                                devInfo.data[index].Sensor.sort((a,b)=>a.Id-b.Id)
                                                            }
                                                            
                                                        }else{
                                                            mm.checked = false
                                                            let sensonTemp =devInfo.data[index].Sensor;
                                                            sensonTemp= sensonTemp.filter(x=>x.Id!==n.Node);
                                                            if(sensonTemp.length>0){
                                                                devInfo.data[index].Sensor = sensonTemp;
                                                            }else{
                                                                devInfo.data=devInfo.data.filter(x=>x.Sn!==n.Sn)
                                                            }
                                                             
                                                        }
                                                        setDevices(devInfo)
                                                        let ttt=devList.filter(x=>x.sn===m.sn)[0].nodes
                                                        // console.log(ttt);
                                                        let checkNodelen= ttt.filter(x=>x.checked).length
                                                        if(checkNodelen===0||checkNodelen===ttt.length){
                                                            m.indeterminate = false
                                                        }else{
                                                            m.indeterminate = true
                                                        }
                                                        if(checkNodelen===ttt.length){
                                                            m.all = true
                                                        }else{
                                                            m.all = false
                                                        }
                                                        let templist=devList.concat()
    
                                                        setDevList(templist)
                                                    }}
                                                    >{mm.label}</Checkbox>
                                                )
                                            })
                                        }
                                        </div>
                                    </div>)
                                })
                            }
                            
                        </div>
                        <Tabs defaultActiveKey="1" tabBarExtraContent={
                            isShowSet&&setState>0&&
                            <Button 
                            disabled={!isShowSet}
                            onClick={()=>{
                                if(devices.type===-1){
                                    SetDevicesWarning()
                                }else if(devices.type===-2){
                                    SetDevicesFree()
                                }
                                else{
                                    setSetType(-3)
                                    if(signEnable){
                                        setShowSgin(true)
                                    }else{
                                        SetFnc();
                                    } 
                                }
                            }}>设置</Button>}
                            onChange={key=>{
                                setIsShowSet(true);
                                if(key==='1'){//上下限
                                    devices.type = 5;
                                }
                                if(key==='2'){//超标
                                    devices.type = 8;
                                }
                                if(key==='3'){//预警
                                    devices.type = -1;
                                }
                                if(key==='4'){//空库
                                    devices.type = -2;
                                }
                                if(key==='5'){//断电
                                    devices.type = 6;
                                }
                                if(key==='6'){//补偿
                                    devices.type = 10;
                                }
                                if(key==='7'){//回传间隔
                                    devices.type = 3;
                                }
                                if(key==='8'){//存储间隔
                                    devices.type = 9;
                                }
                                if(key==='9'){//基本设置
                                    setIsShowSet(false)
                                }
                                let devsTemp = JSON.parse(JSON.stringify(devices));
                                setDevices(devsTemp)
                            }}
                        >
                        <TabPane tab="上下限" key="1">
                            <div className='setItemCon'>
                                <Spin spinning={load}>
                                    <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                                        {
                                            devices.data.map(m=>(
                                                m.Sensor.map(n=>{
                                                    return(
                                                    <div key={m.Sn+''+n.Id}>
                                                        <Col span={24} style={{ display: 'flex', alignItems: 'center' }} >
                                                            <div style={{width:5,height:12,border:'1px solid #2569D8',borderRadius:14,backgroundColor:'#2569D8',marginLeft:10}}></div>
                                                            <div style={{width:150,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis',color:'#2569D8',marginLeft:5}}>{n.Name}</div>
                                                            <div style={{marginLeft:20,width:30,color:'#999'}}>主机</div>
                                                            <div style={{marginLeft:5}}>{m.Sn}</div>
                                                            <div style={{marginLeft:20,width:30,color:'#999'}}>测点</div>
                                                            <div style={{marginLeft:5}}>{n.Id}</div>
                                                        </Col>
                                                        <Col span={24} style={{ display: 'flex', alignItems: 'center' }} >
                                                            <Input.Group style={{ width: 1000 }} compact>
                                                                <Input value={n.TempAlarmHigh} addonBefore={"温度上限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                                    var reg = /^(-?\d+)(\.\d+)?$/
                                                                    if (reg.test(e.target.value)) {
                                                                        n.TempAlarmHigh = parseFloat(e.target.value)
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    } 
                                                                }} />
                                                                <Input value={n.TempAlarmLow} addonBefore={"温度下限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                                    var reg = /^(-?\d+)(\.\d+)?$/
                                                                    if (reg.test(e.target.value)) {
                                                                        n.TempAlarmLow = parseFloat(e.target.value)
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    } 
                                                                }} />
                                                                <Input value={n.HumiAlarmHigh} addonBefore={"湿度上限"} addonAfter={"%RH"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                                    var reg = /^(-?\d+)(\.\d+)?$/
                                                                    if (reg.test(e.target.value)) {
                                                                        n.HumiAlarmHigh = parseFloat(e.target.value)
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    } 
                                                                }} disabled={n.HumiEnable!==0||!n.HumiSwitch}/>
                                                                <Input value={n.HumiAlarmLow} addonBefore={"湿度下限"} addonAfter={"%RH"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                                    var reg = /^(-?\d+)(\.\d+)?$/
                                                                    if (reg.test(e.target.value)) {
                                                                        n.HumiAlarmLow = parseFloat(e.target.value) 
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    } 
                                                                }} disabled={n.HumiEnable!==0||!n.HumiSwitch}/>
                                                            </Input.Group>
                                                        </Col>
                                                            
                                                        
                                                    </div>    
                                                    )
                                                })
                                                
                                            ))
                                        }
                                        
                                    </Row>
                                </Spin>
                            </div>
                        </TabPane>
                        <TabPane tab="超标" key="2">
                            <div className='setItemCon'>
                                <Spin spinning={load}>
                                    <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                                        {
                                            devices.data.map(m=>{
                                                return(
                                                    <div key={m.Sn}>
                                                        <Col span={24} style={{ display: 'flex', alignItems: 'center' }} >
                                                            <div style={{width:5,height:12,border:'1px solid #2569D8',borderRadius:14,backgroundColor:'#2569D8',marginLeft:10}}></div>
                                                            <div style={{width:150,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis',color:'#2569D8',marginLeft:5}}>{m.MName}</div>
                                                            <div style={{marginLeft:20,width:30,color:'#999'}}>主机</div>
                                                            <div style={{marginLeft:5}}>{m.Sn}</div>
                                                        </Col>
                                                        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Input.Group style={{ width: 800 }} compact>
                                                            {/* <Input value={m.Params.OverAlarm.StartTime} addonBefore={"开始时间"} style={{ width: 180, margin: 5 }} onChange={e => {
                                                                let reg = /^\d{2}\:\d{2}$/
                                                                if (reg.test(e.target.value)) {
                                                                    m.Params.OverAlarm.StartTime = e.target.value;
                                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                    setDevices(devsTemp)
                                                                }
                                                            }} /> */}
                                                            {/* <Input value={m.Params.OverAlarm.EndTime} addonBefore={"结束时间"} style={{ width: 180, margin: 5 }} onChange={e => {
                                                                let reg = /^\d{2}\:\d{2}$/
                                                                if (reg.test(e.target.value)) {
                                                                    m.Params.OverAlarm.EndTime = e.target.value;
                                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                    setDevices(devsTemp)
                                                                }
                                                            }} /> */}
                                                            <Input value={m.Params.OverAlarm.Time} addonBefore={"超标时间"} addonAfter={"秒"} style={{ width: 180, margin: 5 }} onChange={e => {
                                                                let reg = intReg
                                                                let val = e.target.value===''?0:e.target.value;
                                                                if (reg.test(val)) {
                                                                    m.Params.OverAlarm.Time = val*1;
                                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                    setDevices(devsTemp)
                                                                }
                                                            }} />
                                                            <Input value={m.Params.OverAlarm.TimeInt} addonBefore={"报警间隔"} addonAfter={"分"} style={{ width: 180, margin: 5 }} maxLength={3} onChange={e => {
                                                                let reg = intReg
                                                                let val = e.target.value===''?1:e.target.value;
                                                                if (reg.test(val)) {
                                                                    m.Params.OverAlarm.TimeInt = val*1;
                                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                    setDevices(devsTemp)
                                                                }
                                                            }} />
                                                            <Input value={m.Params.OverAlarm.TempCount} addonBefore={"温度次数"} addonAfter={"次"} style={{ width: 180, margin: 5 }} maxLength={3} onChange={e => {
                                                                let reg = intReg
                                                                let val = e.target.value===''?0:e.target.value;
                                                                if (reg.test(val)) {
                                                                    let count =Number(val);
                                                                    if(count>255){
                                                                        count=255
                                                                    }
                                                                    m.Params.OverAlarm.TempCount =count;
                                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                    setDevices(devsTemp)
                                                                }
                                                            }} />
                                                            <Input value={m.Params.OverAlarm.HumiCount} addonBefore={"湿度次数"} addonAfter={"次"} maxLength={3} style={{ width: 180, margin: 5 }} onChange={e => {
                                                                let reg = intReg
                                                                let val = e.target.value===''?0:e.target.value;
                                                                if (reg.test(val)) {
                                                                    overAlarm.error = false
                                                                    let count =Number(val);
                                                                    if(count>255){
                                                                        count=255
                                                                    }
                                                                    m.Params.OverAlarm.HumiCount = count;
                                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                    setDevices(devsTemp)
                                                                } 
                                                            }} />
                                                        </Input.Group>
                                                    </Col>
                                                    </div>
                                                    
                                                )
                                            })
                                        }
                                        
                                    </Row>
                                </Spin>
                            </div>
                        </TabPane>
                        <TabPane tab="预警" key="3">
                            <div className='setItemCon'>
                                <Spin spinning={load}>
                                    <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                                        {
                                            devices.data.map(m=>(
                                                m.Sensor.map(n=>{
                                                    return(
                                                        <div key={m.Sn+''+n.Id}>
                                                            <Col span={24} style={{ display: 'flex', alignItems: 'center' }} >
                                                                <div style={{width:5,height:12,border:'1px solid #2569D8',borderRadius:14,backgroundColor:'#2569D8',marginLeft:10}}></div>
                                                                <div style={{width:150,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis',color:'#2569D8',marginLeft:5}}>{n.Name}</div>
                                                                <div style={{marginLeft:20,width:30,color:'#999'}}>主机</div>
                                                                <div style={{marginLeft:5}}>{m.Sn}</div>
                                                                <div style={{marginLeft:20,width:30,color:'#999'}}>测点</div>
                                                                <div style={{marginLeft:5}}>{n.Id}</div>
                                                            </Col>
                                                            <Col span={24} style={{ display: 'flex', alignItems: 'center' }} key={m.Sn+''+n.Id}>
                                                            <Input.Group  compact>
                                                                <Input defaultValue={n.TempWarningHigh} addonBefore={"温度上限"} style={{ width: 150, margin: 5 }} onChange={e => {
                                                                    var reg = /^(-?\d+)(\.\d+)?$/
                                                                    if (reg.test(e.target.value)) {
                                                                        n.TempWarningHigh = parseFloat(e.target.value)
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    }
                                                                }} />
                                                                <Input defaultValue={n.TempWarningLow} addonBefore={"温度下限"} style={{ width: 150, margin: 5 }} onChange={e => {
                                                                    var reg = /^(-?\d+)(\.\d+)?$/
                                                                    if (reg.test(e.target.value)) {
                                                                        n.TempWarningLow = parseFloat(e.target.value)
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    }
                                                                }} />
                                                                <Input defaultValue={n.HumiWarningHigh} addonBefore={"湿度上限"}  style={{ width: 150, margin: 5 }} onChange={e => {
                                                                    var reg = /^(-?\d+)(\.\d+)?$/
                                                                    if (reg.test(e.target.value)) {
                                                                        n.HumiWarningHigh = parseFloat(e.target.value)
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    }
                                                                }}  disabled={n.HumiEnable!==0||!n.HumiSwitch}/>
                                                                <Input defaultValue={n.HumiWarningLow} addonBefore={"湿度下限"}  style={{ width: 150, margin: 5 }} onChange={e => {
                                                                    var reg = /^(-?\d+)(\.\d+)?$/
                                                                    if (reg.test(e.target.value)) {
                                                                        n.HumiWarningLow = parseFloat(e.target.value)
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    } 
                                                                }} disabled={n.HumiEnable!==0||!n.HumiSwitch}/>
                                                                <Input defaultValue={device.Sensor.WarningInterval} addonBefore={"报警间隔"} addonAfter={"分"} style={{ width: 200, margin: 5 }} maxLength={3}
                                                                    onChange={e => {
                                                                        var reg = intReg
                                                                        if (reg.test(e.target.value)) {
                                                                            n.WarningInterval = e.target.value*1
                                                                            let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                            setDevices(devsTemp)
                                                                        } 
                                                                    }} />
                                                            </Input.Group>
                                                        </Col>
                                                        </div>
                                                        
                                                    )
                                                })
                                                
                                            ))
                                        }
                                        
                                    </Row>
                                </Spin>
                            </div>
                        </TabPane>
                        {/* <TabPane tab="空库" key="4">
                            <div className='setItemCon'>
                                <Spin spinning={load}>
                                    <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                                        {
                                            devices.data.map(m=>(
                                                m.Sensor.map(n=>{
                                                    return(
                                                        <Col span={24} style={{ display: 'flex', alignItems: 'center' }} key={m.Sn+''+n.Id}>
                                                            <div style={{width:150,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}}>{n.Name}</div>
                                                            <div style={{marginLeft:5,width:30}}>主机</div>
                                                            <div style={{marginLeft:5}}>{m.Sn}</div>
                                                            <div style={{marginLeft:5,width:30}}>测点</div>
                                                            <div style={{marginLeft:5}}>{n.Id}</div>
                                                            <Checkbox checked={n.Hide>1} onChange={e=>{
                                                                if(e.target.checked){
                                                                    n.Hide=2
                                                                }else{
                                                                    n.Hide=0
                                                                }
                                                                let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                setDevices(devsTemp)
                                                            }}>空库</Checkbox>
                                                            <Checkbox checked={n.Hide===2} onChange={e=>{
                                                                n.Hide = e.target.checked?2:3;
                                                                let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                setDevices(devsTemp)
                                                                // setDevHide();
                                                            }}>存储数据</Checkbox>
                                                            
                                                        </Col>
                                                    )
                                                })
                                                
                                            ))
                                        }
                                        
                                    </Row>
                                </Spin>
                            </div>
                        </TabPane> */}
                        <TabPane tab="断电" key="5">
                            <div className='setItemCon'>
                                <Spin spinning={load}>
                                    <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                                        {
                                            devices.data.map(m=>{
                                                return(
                                                    <div key={m.Sn}>
                                                        <Col span={24} style={{ display: 'flex', alignItems: 'center' }} >
                                                            <div style={{width:5,height:12,border:'1px solid #2569D8',borderRadius:14,backgroundColor:'#2569D8',marginLeft:10}}></div>
                                                            <div style={{width:150,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis',color:'#2569D8',marginLeft:5}}>{m.MName}</div>
                                                            <div style={{marginLeft:20,width:30,color:'#999'}}>主机</div>
                                                            <div style={{marginLeft:5}}>{m.Sn}</div>
                                                        </Col>
                                                        <Col span={24} style={{ display: 'flex', alignItems: 'center' }} >
                                                            <Input.Group style={{ width: 800 }} compact>
                                                                <Input value={m.Params.PowerAlarmTimeCount} addonBefore={"断电时间"} addonAfter={"秒"} style={{ width: 180, margin: 5 }} onChange={e => {
                                                                    var reg =  intReg
                                                                    let val = e.target.value===''?0:e.target.value;
                                                                    if (reg.test(val)) {
                                                                        m.Params.PowerAlarmTimeCount = val*1;
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    } 
                                                                }} />
                                                                <Input value={m.Params.PowerAlarmMsgIntCount} addonBefore={"报警间隔"} addonAfter={"分钟"} style={{ width: 180, margin: 5 }} maxLength={3} onChange={e => {
                                                                    let reg = intReg
                                                                    let val = e.target.value===''?1:e.target.value;
                                                                    if (reg.test(val)) {
                                                                        m.Params.PowerAlarmMsgIntCount = val*1;
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    }
                                                                }} />
                                                                <Input value={m.Params.PowerAlarmMsgCount} addonBefore={"报警次数"} addonAfter={"次"} maxLength={3} style={{ width: 180, margin: 5 }} onChange={e => {
                                                                    var reg =  intReg
                                                                    let val = e.target.value===''?0:e.target.value;
                                                                    if (reg.test(val)) {
                                                                        m.Params.PowerAlarmMsgCount = val*1;
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    }
                                                                }} />
                                                            </Input.Group>
                                                        </Col>
                                                    </div>
                                                )                                                
                                            })
                                        }
                                        
                                    </Row>
                                </Spin>
                            </div>
                        </TabPane>
                        {
                            setState>1 &&
                            <TabPane tab="补偿" key="6">
                            <div className='setItemCon'>
                                <Spin spinning={load}>
                                    <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                                        {
                                            devices.data.map(m=>(
                                                m.Sensor.map(n=>{
                                                    return(
                                                        <div key={m.Sn+''+n.Id} style={{width:'100%'}}>
                                                            <Col span={24} style={{ display: 'flex', alignItems: 'center' }} >
                                                                <div style={{width:5,height:12,border:'1px solid #2569D8',borderRadius:14,backgroundColor:'#2569D8'}}></div>
                                                                <div style={{width:150,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis',color:'#2569D8',marginLeft:5}}>{n.Name}</div>
                                                                <div style={{marginLeft:20,width:30,color:'#999'}}>主机</div>
                                                                <div style={{marginLeft:5}}>{m.Sn}</div>
                                                                <div style={{marginLeft:20,width:30,color:'#999'}}>测点</div>
                                                                <div style={{marginLeft:5}}>{n.Id}</div>
                                                            </Col>
                                                            <Col span={24} style={{ display: 'flex', alignItems: 'center' }} >
                                                                {/* <Input value={n.TempCompensate} addonBefore={"温度补偿"}  style={{ width: 150, margin: 5 }} onChange={e => {
                                                                    let reg = /^-?(([01]{1}(\.\d+)?)|([2]{1}(\.[0-5]{1})?))$/
                                                                    let errMsg = '输入-2.5至2.5之间的一位小数的数字'
                                                                    if(Mode==='soc'){
                                                                        if(state.main.type<103){
                                                                            reg =/^-?(([1]?\d{1}(\.\d{1})?)|([2]{1}[0-4]{1}(\.\d{1})?)|([2]{1}[5]{1}))$/
                                                                            errMsg = '输入-25至25之间的一位小数的数字'
                                                                        }
                                                                    }else{
                                                                        if(state.main.type<102&&isStrong){
                                                                            reg =/^-?(([1]?\d{1}(\.\d{1})?)|([2]{1}[0-4]{1}(\.\d{1})?)|([2]{1}[5]{1}))$/
                                                                            errMsg = '输入-25至25之间的一位小数的数字'
                                                                        }
                                                                    }
                                                                    n.HumiCompensate
                                                                    if (reg.test(e.target.value)) {
                                                                        revise.error = false
                                                                        setDevice({ ...device, Sensor: { ...device.Sensor, TempCompensate: parseFloat(e.target.value) } })
                                                                    } else {
                                                                        message.error(errMsg);
                                                                        revise.error = true
                                                                        setDevice({ ...device, Sensor: { ...device.Sensor, TempCompensate: (e.target.value) } })
                                                                    }
                                                                }} />
                                                                <Input value={n.HumiCompensate} addonBefore={"湿度补偿"}  style={{ width: 150, margin: 5 }} onChange={e => {
                                                                    let reg = /^-?(([1]?\d{1}(\.\d{1})?)|([2]{1}[0-4]{1}(\.\d{1})?)|([2]{1}[5]{1}))$/
                                                                    if (reg.test(e.target.value)) {
                                                                        revise.error = false
                                                                        setDevice({ ...device, Sensor: { ...device.Sensor, HumiCompensate: parseFloat(e.target.value) } })
                                                                    } else {
                                                                        message.error('输入-25至25之间的一位小数的数字');
                                                                        revise.error = true
                                                                        setDevice({ ...device, Sensor: { ...device.Sensor, HumiCompensate: (e.target.value) } })
                                                                    }
                                                                }} /> */}
                                                                <div style={{color: '#999',border:'1px solid #ccc',padding:'3px 8px'}}>温度</div>
                                                                    <InputNumber style={{width:80,margin:0}}  value = {n.TempCompensate} placeholder='0' onChange={e => {
                                                                        n.TempCompensate = e;
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    }} />
                                                                    <div style={{color: '#999',border:'1px solid #ccc',padding:'3px 8px'}}>湿度</div>
                                                                    <InputNumber style={{width:80}} value={n.HumiCompensate}  placeholder='0' onChange={e => {
                                                                        n.HumiCompensate = e;
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    }}  />
                                                            </Col>
                                                        </div>
                                                    )
                                                })
                                                
                                            ))
                                        }
                                        
                                    </Row>
                                </Spin>
                            </div>
                            </TabPane>
                        }
                        <TabPane tab="回传间隔" key="7">
                            <div className='setItemCon'>
                                <Spin spinning={load}>
                                    <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                                        {
                                            devices.data.map(m=>{
                                                return(
                                                    <div key={m.Sn} style={{width:'100%'}}>
                                                        <Col span={24} style={{ display: 'flex', alignItems: 'center' }} >
                                                            <div style={{width:5,height:12,border:'1px solid #2569D8',borderRadius:14,backgroundColor:'#2569D8'}}></div>
                                                            <div style={{width:150,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis',color:'#2569D8',marginLeft:5}}>{m.MName}</div>
                                                            <div style={{marginLeft:20,width:30,color:'#999'}}>主机</div>
                                                            <div style={{marginLeft:5}}>{m.Sn}</div>
                                                        </Col>
                                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }} >
                                                        
                                                        <Input style={{width:260}} value={m.Params.RealTimeInt} addonBefore={"回传间隔"} addonAfter={"秒"} onChange={e => {
                                                            var reg =  /^[\d]{0,4}$/
                                                            let val = e.target.value===''?0:e.target.value;
                                                            if (reg.test(val)) {
                                                                m.Params.RealTimeInt = val*1;
                                                                let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                setDevices(devsTemp)
                                                                // setDevice({ ...device, Params: { ...device.Params, RealTimeInt: Number(val) } })
                                                            } 
                                                        }} />
                                                    </Col>
                                                    </div>
                                                )                                                
                                            })
                                        }
                                        
                                    </Row>
                                </Spin>
                            </div>
                        </TabPane>
                        <TabPane tab="存储间隔" key="8">
                            <div className='setItemCon'>
                                <Spin spinning={load}>
                                    <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                                        {
                                            devices.data.map(m=>{
                                                return(
                                                    <div key={m.Sn} style={{width:'100%'}}>
                                                        <Col span={24} style={{ display: 'flex', alignItems: 'center' }} >
                                                            <div style={{width:5,height:12,border:'1px solid #2569D8',borderRadius:14,backgroundColor:'#2569D8'}}></div>
                                                            <div style={{width:150,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis',color:'#2569D8',marginLeft:5}}>{m.MName}</div>
                                                            <div style={{marginLeft:20,width:30,color:'#999'}}>主机</div>
                                                            <div style={{marginLeft:5}}>{m.Sn}</div>
                                                        </Col>
                                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }} >
                                                        <Space>
                                                            <Input value={m.Params.NorStoreInt} addonBefore={"正常存储间隔"} addonAfter={"分"} onChange={e => {
                                                                let reg =  intReg
                                                                let val = e.target.value===''?0:e.target.value;
                                                                if (reg.test(val)) {
                                                                    m.Params.NorStoreInt = val*1;
                                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                    setDevices(devsTemp)
                                                                    // setDevice({ ...device, Params: { ...device.Params, NorStoreInt: Number(val) } })
                                                                }
                                                            }} />
                                                            <Input value={m.Params.AlmStoreInt} addonBefore={"报警存储间隔："} addonAfter={"分"} onChange={e => {
                                                                let reg = intReg
                                                                let val = e.target.value===''?0:e.target.value;
                                                                if (reg.test(val)) {
                                                                    m.Params.AlmStoreInt = val*1;
                                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                    setDevices(devsTemp)
                                                                    // setDevice({ ...device, Params: { ...device.Params, AlmStoreInt: Number(val) } })
                                                                } 
                                                            }}/>
                                                        </Space>
                                                    </Col>
                                                    </div>
                                                )                                                
                                            })
                                        }
                                        
                                    </Row>
                                </Spin>
                            </div>
                        </TabPane>
                        <TabPane tab="统一设置" key="9">
                            <div className='setItemCon'>
                                <Spin spinning={load}>
                                <Row gutter={[20, 5]} style={{ position: 'relative' }}>
                                    {/* 温湿度预警 */}
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{width:5,height:12,border:'1px solid #2569D8',borderRadius:14,backgroundColor:'#2569D8'}}></div>
                                        <div >预警参数</div>
                                    </Col>
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Input.Group>
                                            <Input defaultValue={device.Sensor.TempWarningHigh} addonBefore={"温度上限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningHigh: parseFloat(e.target.value) } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningHigh: 0 } })
                                                }
                                            }} />
                                            <Input defaultValue={device.Sensor.TempWarningLow} addonBefore={"温度下限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningLow: parseFloat(e.target.value) } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningLow: 0 } })
                                                }
                                            }} />
                                            <Input defaultValue={device.Sensor.HumiWarningHigh} addonBefore={"湿度上限"} addonAfter={"%RH"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiWarningHigh: parseFloat(e.target.value) } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiWarningHigh: 0 } })
                                                }
                                            }}  disabled={device.Humi===0}/>
                                            <Input defaultValue={device.Sensor.HumiWarningLow} addonBefore={"湿度下限"} addonAfter={"%RH"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiWarningLow: parseFloat(e.target.value) } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiWarningLow: 0 } })
                                                }
                                            }} disabled={device.Humi===0}/>
                                             <Input defaultValue={device.Sensor.WarningInterval} addonBefore={"报警间隔"} addonAfter={"分"} style={{ width: 180, margin: 5 }} maxLength={3}
                                            onChange={e => {
                                                var reg = intReg
                                                let val = e.target.value===''?1:e.target.value;
                                                if (reg.test(val)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, WarningInterval: val*1 } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, WarningInterval: 2 } })
                                                }
                                            }} />
      
                                        </Input.Group>
                                        <Space>

                                            {
                                                setState>0&&
                                                <Button onClick={()=>{
                                                    devices.type = -1;
                                                    devices.data.map(m=>{
                                                        m.Sensor.map(n=>{
                                                            n.TempWarningHigh = device.Sensor.TempWarningHigh
                                                            n.TempWarningLow = device.Sensor.TempWarningLow
                                                            n.HumiWarningHigh = device.Sensor.HumiWarningHigh
                                                            n.HumiWarningLow = device.Sensor.HumiWarningLow
                                                            n.WarningInterval = device.Sensor.WarningInterval
                                                        })
                                                    })
                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                    setDevices(devsTemp)
                                                    SetDevicesWarning()
                                                }} >设置</Button>
                                            }
                                            
                                        </Space>
                                    </Col>
                                    <Divider style={{ margin: '1px 0' }} />
                                    {/* 温湿度报警 */}
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{width:5,height:12,border:'1px solid #2569D8',borderRadius:14,backgroundColor:'#2569D8'}}></div>
                                        <div >报警参数</div>
                                    </Col>
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Input.Group >
                                            <Input value={device.Sensor.TempAlarmHigh} addonBefore={"温度上限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempAlarmHigh: parseFloat(e.target.value) } })
                                                } else {
                                                    sensorAlarm.error = true
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempAlarmHigh: (e.target.value) } })
                                                }
                                            }} />
                                            <Input value={device.Sensor.TempAlarmLow} addonBefore={"温度下限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    sensorAlarm.error = false
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempAlarmLow: parseFloat(e.target.value) } })
                                                } else {
                                                    sensorAlarm.error = true
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempAlarmLow: (e.target.value) } })
                                                }
                                            }} />
                                            <Input value={device.Sensor.HumiAlarmHigh} addonBefore={"湿度上限"} addonAfter={"%RH"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    sensorAlarm.error = false
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiAlarmHigh: parseFloat(e.target.value) } })
                                                } else {
                                                    sensorAlarm.error = true
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiAlarmHigh: (e.target.value) } })
                                                }
                                            }} disabled={device.Humi===0}/>
                                            <Input value={device.Sensor.HumiAlarmLow} addonBefore={"湿度下限"} addonAfter={"%RH"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    sensorAlarm.error = false
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiAlarmLow: parseFloat(e.target.value) } })
                                                } else {
                                                    sensorAlarm.error = true
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiAlarmLow: (e.target.value) } })
                                                }
                                            }} disabled={device.Humi===0}/>
                                        </Input.Group>
                                        <Space>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    devices.type = 5;
                                                    devices.data.map(m=>{
                                                        m.Sensor.map(n=>{
                                                            n.TempAlarmHigh = device.Sensor.TempAlarmHigh
                                                            n.TempAlarmLow = device.Sensor.TempAlarmLow
                                                            n.HumiAlarmHigh = device.Sensor.HumiAlarmHigh
                                                            n.HumiAlarmLow = device.Sensor.HumiAlarmLow
                                                        })
                                                    })
                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                    setDevices(devsTemp)
                                                    // SetDevicesParams()
                                                    setSetType(-3)
                                                    if(signEnable){
                                                        setShowSgin(true)
                                                    }else{
                                                        SetFnc();
                                                    } 
                                                }} disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                            
                                        </Space>
                                    </Col>
                                    <Divider style={{ margin: '1px 0' }} />
                                    {/* 超标报警 */}
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{width:5,height:12,border:'1px solid #2569D8',borderRadius:14,backgroundColor:'#2569D8'}}></div>
                                        <div >超标参数</div>
                                    </Col>
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Input.Group compact>
                                           
                                            <Input value={device.Params.OverAlarm.Time} addonBefore={"超标时间"} addonAfter={"秒"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = intReg
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    overAlarm.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, Time: Number(val) } } })
                                                } else {
                                                    overAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, Time:val } } })
                                                }
                                            }} />
                                            <Input value={device.Params.OverAlarm.TimeInt} addonBefore={"报警间隔"} addonAfter={"分"} style={{ width: 200, margin: 5 }} maxLength={3} onChange={e => {
                                                var reg = intReg
                                                let val = e.target.value===''?1:e.target.value;
                                                if (reg.test(val)) {
                                                    overAlarm.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, TimeInt: Number(val) } } })
                                                } else {
                                                    overAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, TimeInt: val } } })
                                                }
                                            }} />
                                            <Input value={device.Params.OverAlarm.TempCount} addonBefore={"温度次数"} addonAfter={"次"} style={{ width: 200, margin: 5 }} maxLength={3} onChange={e => {
                                                var reg = intReg
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    overAlarm.error = false
                                                    let count =Number(val);
                                                    if(count>255){
                                                        count=255
                                                    }
                                                    setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, TempCount: count} } })
                                                } else {
                                                    overAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, TempCount: val } } })
                                                }
                                            }} />
                                            <Input value={device.Params.OverAlarm.HumiCount} addonBefore={"湿度次数"} addonAfter={"次"} maxLength={3} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = intReg
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    overAlarm.error = false
                                                    let count =Number(val);
                                                    if(count>255){
                                                        count=255
                                                    }
                                                    setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, HumiCount: count } } })
                                                } else {
                                                    overAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, OverAlarm: { ...device.Params.OverAlarm, HumiCount: val } } })
                                                }
                                            }} />
                                        </Input.Group>
                                        <Space>
                                            {
                                                setState>0&&
                                                <Button onClick={() =>{ 
                                                    // SetDeviceParams(overAlarm);
                                                    devices.type = 8;
                                                    devices.data.map(m=>{
                                                        m.Params.OverAlarm.StartTime = device.Params.OverAlarm.StartTime
                                                        m.Params.OverAlarm.EndTime=device.Params.OverAlarm.EndTime
                                                        m.Params.OverAlarm.Time=device.Params.OverAlarm.Time
                                                        m.Params.OverAlarm.TimeInt=device.Params.OverAlarm.TimeInt
                                                        m.Params.OverAlarm.TempCount=device.Params.OverAlarm.TempCount
                                                        m.Params.OverAlarm.HumiCount=device.Params.OverAlarm.HumiCount
                                                        
                                                    })
                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                    setDevices(devsTemp)
                                                    setSetType(-3)
                                                    if(signEnable){
                                                        setShowSgin(true)
                                                    }else{
                                                        SetFnc();
                                                    } 
                                                }} 
                                                disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                        </Space>
                                    </Col>
                                    <Divider style={{ margin: '1px 0' }} />
                                    {/* 断电 */}
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{width:5,height:12,border:'1px solid #2569D8',borderRadius:14,backgroundColor:'#2569D8'}}></div>
                                        <div >断电参数</div>
                                    </Col>
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Input.Group >
                                            <Input value={device.Params.PowerAlarmTimeCount} addonBefore={"断电时间"} addonAfter={"秒"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg =  intReg
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    powerAlarm.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, PowerAlarmTimeCount: Number(val) } })
                                                } else {
                                                    powerAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, PowerAlarmTimeCount: val } })
                                                }
                                            }} />
                                            <Input value={device.Params.PowerAlarmMsgIntCount} addonBefore={"报警间隔"} addonAfter={"分"} style={{ width: 200, margin: 5 }} maxLength={3} onChange={e => {
                                                var reg = intReg
                                                let val = e.target.value===''?1:e.target.value;
                                                if (reg.test(val)) {
                                                    powerAlarm.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, PowerAlarmMsgIntCount: Number(val) } })
                                                } else {
                                                    powerAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, PowerAlarmMsgIntCount: (val) } })
                                                }
                                            }} />
                                            <Input value={device.Params.PowerAlarmMsgCount} addonBefore={"报警次数"} addonAfter={"次"} maxLength={3} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg =  intReg
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    powerAlarm.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, PowerAlarmMsgCount: parseFloat(val) } })
                                                } else {
                                                    powerAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, PowerAlarmMsgCount: (val) } })
                                                }
                                            }} />
                                        </Input.Group>
                                        <Space>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    devices.type = 6;
                                                    devices.data.map(m=>{
                                                        m.Params.PowerAlarmTimeCount = device.Params.PowerAlarmTimeCount
                                                        m.Params.PowerAlarmMsgIntCount = device.Params.PowerAlarmMsgIntCount
                                                        m.Params.PowerAlarmMsgCount = device.Params.PowerAlarmMsgCount
                                                    })
                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                    setDevices(devsTemp)
                                                    setSetType(-3)
                                                    if(signEnable){
                                                        setShowSgin(true)
                                                    }else{
                                                        SetFnc();
                                                    } 
                                                }} disabled={Mode!=='soc'&&state.main.type === 104}
                                                >设置</Button>
                                            }
                                        </Space>
                                    </Col>
                                    <Divider style={{ margin: '1px 0' }} />
                                    {/* <Col span={24} style={{ display: 'flex' }}>
                                        <Space>
                                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                <span style={{ border: '1px solid #ccc', display: 'inline-block', width: 110, margin: 0, padding: 5, borderRightColor: 'transparent' }}>读取历史数据：</span>
                                                <RangePicker style={{ margin: 0 }}
                                                    ranges={{
                                                        今天: [moment().startOf('day'), moment()],
                                                        '本周': [moment().startOf('week'), moment().endOf('week')],
                                                        '本月': [moment().startOf('month'), moment().endOf('month')],
                                                    }}
                                                    showTime
                                                    format="YYYY-MM-DD HH:mm"
                                                    onChange={(dates, dateStrings) => {
                                                        // console.log(dateStrings)
                                                        if (dateStrings[0] !== "") {
                                                            setTimeReadDataStart({ validity: true, error: false, value:dateStrings[0] })
                                                            setTimeReadDataEnd({ validity: true, error: false, value:dateStrings[1] })
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <Button onClick={() => {ReadDeviceData();}}>读取</Button>
                                        </Space>
                                    </Col> */}
                                    {/* 存储间隔 */}
                                    <Col span={12} style={{ display: 'flex',paddingRight:50}}>
                                        <Space>
                                            <Input value={device.Params.NorStoreInt} addonBefore={"正常间隔"} addonAfter={"分"} onChange={e => {
                                                var reg =  intReg
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    store.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, NorStoreInt: Number(val) } })
                                                } else {
                                                    store.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, NorStoreInt: val } })
                                                }
                                            }} style={{width:160}} />
                                            <Input value={device.Params.AlmStoreInt} disabled={setState<1} addonBefore={"报警间隔"} addonAfter={"分"} onChange={e => {

                                                var reg = intReg
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    store.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, AlmStoreInt: Number(val) } })
                                                } else {
                                                    store.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, AlmStoreInt: val } })
                                                }
                                            }} style={{width:160}}/>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    devices.type = 9;
                                                    devices.data.map(m=>{
                                                        m.Params.NorStoreInt = device.Params.NorStoreInt
                                                        m.Params.AlmStoreInt = device.Params.AlmStoreInt
                                                    })
                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                    setDevices(devsTemp)
                                                    setSetType(-3)
                                                    if(signEnable){
                                                        setShowSgin(true)
                                                    }else{
                                                        SetFnc();
                                                    } 
                                                }} disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                        </Space>
                                    </Col>
                                    {/* 回传间隔 */}
                                    <Col span={12} style={{ display: 'flex' }}>
                                        <Space>
                                            <Input style={{width:260}} value={device.Params.RealTimeInt} addonBefore={"实时间隔"} addonAfter={"秒"} onChange={e => {
                                                var reg =  /^[\d]{0,4}$/
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    setDevice({ ...device, Params: { ...device.Params, RealTimeInt: Number(val) } })
                                                } else {
                                                    setDevice({ ...device, Params: { ...device.Params, RealTimeInt: val } })
                                                }
                                            }} />
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    devices.type = 3;
                                                    devices.data.map(m=>{
                                                        m.Params.RealTimeInt = device.Params.RealTimeInt
                                                    })
                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                    setDevices(devsTemp)
                                                    setSetType(-3)
                                                    if(signEnable){
                                                        setShowSgin(true)
                                                    }else{
                                                        SetFnc();
                                                    } 
                                                }} 
                                                disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                        </Space>
                                    </Col>
                                    {/* <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Space>
                                        <Input.Group  >
                                            <Input value={device.Params.BatPowerAlarm} addonBefore={"主机电量"} style={{ width: 160}} onChange={e => {
                                                var reg =  intReg
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    batteryAlarm.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, BatPowerAlarm: Number(val) } })
                                                } else {
                                                    batteryAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, BatPowerAlarm: val } })
                                                }
                                            }} />
                                            <Input value={device.Sensor.BatPowerAlarm} addonBefore={"探头电量"} style={{ width: 160, margin: 5 }} onChange={e => {
                                                var reg = intReg
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    batteryAlarm.error = false
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, BatPowerAlarm: Number(val) } })
                                                } else {
                                                    batteryAlarm.error = true
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, BatPowerAlarm: (val) } })
                                                }
                                            }} />
                                        </Input.Group>
                                        
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    // SetDeviceParams(batteryAlarm);
                                                    devices.type = 7;
                                                    devices.data.map(m=>{
                                                        m.Params.BatPowerAlarm = device.Params.BatPowerAlarm
                                                        m.Sensor.map(n=>{
                                                            n.BatPowerAlarm = device.Sensor.BatPowerAlarm
                                                        })
                                                    })
                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                    setDevices(devsTemp)
                                                    SetDevicesParams()
                                                }} 
                                                disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                        </Space>
                                    </Col> */}
                                    <Divider style={{ margin: '1px 0' }} />
                                    {/* 空库 */}
                                    <Col span={12} style={{ display: 'flex', alignItems: 'center',}}>
                                        <div  style={{border: '1px solid #ccc', width: 80, margin: 0, padding: '5px 10px', borderRightColor: 'transparent'  }}>空库设置</div>
                                        <div   style={{border: '1px solid #ccc', width: 250, margin: 0, padding: '5px 10px' }}>
                                        <Checkbox checked={device.Sensor.Hide>0} onChange={e=>{
                                            setDevice({...device,Sensor:{...device.Sensor,Hide:e.target.checked?2:0}});
                                        }}>空库</Checkbox>
                                        <Checkbox checked={device.Sensor.Hide===2} onChange={e=>{
                                            setDevice({...device,Sensor:{...device.Sensor,Hide:e.target.checked?2:3}});
                                        }}>存储</Checkbox>
                                        </div>
                                        
                                        {
                                            setState>0&&
                                            <Button onClick={()=>{
                                                devices.type = -2;
                                                devices.data.map(m=>{
                                                    m.Sensor.map(n=>{
                                                        n.Hide =  device.Sensor.Hide
                                                    })
                                                })
                                                let devsTemp = JSON.parse(JSON.stringify(devices));
                                                setDevices(devsTemp)
                                                SetDevicesFree()
                                            }} style={{marginLeft:8}}
                                            disabled={Mode!=='soc'&&state.main.type === 104}
                                            >设置</Button>
                                        }   
                                    </Col>
                                    
                                    {/* 设备关机 */}       
                                    <Col span={12} style={{ display: 'flex' }}>
                                        <Space>
                                            <Input addonBefore={"设备操作"} disabled value={"请谨慎操作"} style={{width:260}} />
                                            <Button onClick={()=>{
                                                // setDevClose({...device, Params: {...device.Params}});
                                                devices.type = 11;
                                                devices.data.map(m=>{
                                                    m.Params= device.Params
                                                })
                                                let devsTemp = JSON.parse(JSON.stringify(devices));
                                                setDevices(devsTemp)
                                                setSetType(-3)
                                                if(signEnable){
                                                    setShowSgin(true)
                                                }else{
                                                    SetFnc();
                                                } 
                                            }} disabled={setState<1}>关机</Button>
                                            {/* <Button>重启</Button> */}
                                        </Space>
                                    </Col>
                                    <Divider style={{ margin: '1px 0' }} />
                                    
                                    {/* IP地址 */}
                                    <Col span={12} style={{ display: 'flex' }}>
                                        <Space>
                                            <Input style={{width:328}} value={device.Params.IPAddress} addonBefore={"网络地址"} placeholder='xxx.xxx.xxx.xxx:5566' onChange={e => {
                                                setDevice({ ...device, Params: { ...device.Params, IPAddress: e.target.value } })
                                            }} />

                                            {
                                                setState>0&&state.main.type<103&&
                                                <Button onClick={() =>{ 
                                                    // SetDeviceParams(serverIP);
                                                    devices.type = 1;
                                                    devices.data.map(m=>{
                                                        m.Params.IPAddress = device.Params.IPAddress
                                                    })
                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                    setDevices(devsTemp)
                                                    setSetType(-3)
                                                    if(signEnable){
                                                        setShowSgin(true)
                                                    }else{
                                                        SetFnc();
                                                    } 
                                                }} 
                                                disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                            
                                        </Space>
                                    </Col>
                                    
                                    {/* 探头停用 */}
                                    <Col span={12} style={{ display: 'flex', alignItems: 'center', }}>
                                        <div style={{border: '1px solid #ccc', width: 80, margin: 0, padding: '5px 10px', borderRightColor: 'transparent'  }}>探头停用</div>
                                        <div style={{border: '1px solid #ccc', width: 180, margin: 0, padding: '5px 20px' }}>
                                            <Checkbox checked={device.Sensor.Enable == 1 ? true : false} onChange={e => {
                                                setDevice({ ...device, Sensor: { ...device.Sensor, Enable: e.target.checked ? 1 : 0 } })
                                            }}>停用</Checkbox>
                                        </div>
                                        <Space>
                                        {
                                            setState>0&&
                                            <Button onClick={() => {
                                                devices.type = 4;
                                                console.log(devices);
                                                
                                                devices.data.map(m=>{
                                                    m.Sensor.map(n=>{
                                                        n.Enable= device.Sensor.Enable
                                                    })
                                                })
                                                let devsTemp = JSON.parse(JSON.stringify(devices));
                                                setDevices(devsTemp)
                                                setSetType(-3)
                                                if(signEnable){
                                                    setShowSgin(true)
                                                }else{
                                                    SetFnc();
                                                } 
                                            }} disabled={Mode!=='soc'&&state.main.type === 104} style={{marginLeft:10}}>设置</Button>
                                        }
                                        </Space>

                                    </Col>
                                    <Divider style={{ margin: '1px 0' }} />
                                    {/* <Col span={6} >
                                        
                                        <div>探头连接失败报警：<Button onClick={() => {
                                            SetDeviceLinkAlarm(device.Sensor.LinkAlarm === 0 ? 1 : 0);
                                        }}>{device.Sensor.LinkAlarm === 0 ? "已关闭" : "已打开"}</Button></div>
                                    </Col> */}
                                    {/* 设备时间 */}
                                    <Col span={12} style={{ display: 'flex'}}>
                                        <Space>
                                            <Input value={device.Params.DeviceTime} addonBefore={"设备时间"} disabled  style={{width:328}} />
                                            {
                                                setState>0&&
                                                <Button onClick={() =>{
                                                    devices.type = 2;
                                                    devices.data.map(m=>{
                                                        m.Params.DeviceTime = device.Params.DeviceTime
                                                    })
                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                    setDevices(devsTemp)
                                                    setSetType(-3)
                                                    if(signEnable){
                                                        setShowSgin(true)
                                                    }else{
                                                        SetFnc();
                                                    } 
                                                } } disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                        </Space>
                                    </Col>
                                    {/* 补偿 */}
                                    <Col span={12} >
                                        {
                                            setState>1?
                                                <div style={{ display: 'flex' }}>
                                                <div style={{border: '1px solid #ccc', width: 80, margin: 0, padding: '5px 10px', borderRightColor: 'transparent'  }}>温度补偿</div>
                                                <InputNumber style={{width:70,margin:0}}  value = {device.Sensor.TempCompensate} placeholder='0' onChange={e => {
                                                    let value = e;
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempCompensate: value } })
                                                }} />
                                                <div style={{border: '1px solid #ccc', width: 80, margin: 0, padding: '5px 10px', borderRightColor: 'transparent'  }}>湿度补偿</div>
                                                <InputNumber style={{width:70}} value={device.Sensor.HumiCompensate}  placeholder='0' onChange={e => {

                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiCompensate:e } })
                                                }}  />
                                                <Space>
                                                {
                                                    setState>0&&
                                                    <Button onClick={() =>{ 
                                                        devices.type = 10;
                                                        devices.data.map(m=>{
                                                            m.Sensor.map(n=>{
                                                                n.TempCompensate = device.Sensor.TempCompensate
                                                                n.HumiCompensate = device.Sensor.HumiCompensate
                                                            })
                                                        })
                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                        setDevices(devsTemp)
                                                        setSetType(-3)
                                                        if(signEnable){
                                                            setShowSgin(true)
                                                        }else{
                                                            SetFnc();
                                                        } 
                                                    }}  >设置</Button>
                                                }
                                            </Space>
                                            </div>
                                        : <div></div>
                                        }
                                    </Col>
                                </Row>
                            </Spin>
                            </div>
                        </TabPane>
                    </Tabs>  
                    </TabPane>
                }
            </Tabs>
             
            
            <Modal title='身份认证' centered visible={showAffirm} destroyOnClose={true} 
            onOk={()=>{
                let demos =state_device.devicestate.filter(x=>x.Password===affirmPwd)
                if(demos){
                    console.log('密码正确',demos.length);
                    
                }else{
                    console.log('密码不对');
                }
                let s = 0
                if(state.main.account==='湖北亿昊药业有限公司'){
                    if(affirmPwd.startsWith("湖北亿昊药业有限公司")){
                        let strs = affirmPwd.split('+');
                        if(strs.length>1){
                            if(md5(strs[1])===state.main.password){
                                s=2;
                                setShowAffirm(false);
                            }else{
                                s=0;
                                message.warn('密码错误');
                            }
                        }else{
                            s=0;
                            message.warn('密码错误');
                        }
                        
                    }else{
                        if(md5(affirmPwd)===state.main.password){
                            s=1;
                            setShowAffirm(false);
                        }else{
                            s=0;
                            message.warn('密码错误');
                        }
                    }
                }else{
                    if(affirmPwd.startsWith("super")){
                        if(md5(affirmPwd.slice(5))===state.main.password){
                            s=2;
                            setShowAffirm(false);
                        }else{
                            s=0;
                            message.warn('密码错误');
                        }
                    }else{
                        if(md5(affirmPwd)===state.main.password){
                            s=1;
                            setShowAffirm(false);
                        }else if(affirmPwd===props.item.Password||affirmPwd===props.item.Password+'s'){
                            s=2;
                            setShowAffirm(false)
                        }else if(affirmPwd===(props.item.Password+'sup')){
                            s=3;
                            setShowAffirm(false)
                        }
                        else{
                            s=0;
                            message.warn('密码错误');
                        }
                    }
                }
                setSetState(s);
                props.changeSetState(s);
                dispatch({type:'super',super:s})
                dispatch({type:'setState',setState:s===0?0:(Mode==='soc'&&state.main.type<102)?2:1})
            }}  onCancel={()=>setShowAffirm(false)}>
                <Input.Password addonBefore='输入密码:' onChange={e=>setAffirmPwd(e.target.value)} />
            </Modal>
        </div>
    )
}

export default DeviceSet