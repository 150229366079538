import {
    DashboardOutlined,
    FundProjectionScreenOutlined,
    FieldTimeOutlined,
    AlertOutlined,
    CarryOutOutlined,
    DeliveredProcedureOutlined,
    ControlOutlined,
    VideoCameraOutlined,
    UngroupOutlined,
    TeamOutlined,
    DatabaseOutlined,
    BranchesOutlined,
    NodeIndexOutlined,
    PullRequestOutlined,
    QuestionCircleOutlined,
    SafetyCertificateOutlined,
    BarsOutlined,
} from '@ant-design/icons';


const menu = [
    // 仪表盘
    {
        name: '仪表盘',
        key: '1',
        path: '/',
        auth: false,
        local: true,
        u_type: 104,
        icon: < DashboardOutlined />,
    }, 
    // 数据监控
    {
        name: '数据监控',
        path: '/monitor',
        auth: false,
        local: true,
        u_type: 105,
        icon: < DatabaseOutlined />,
        key: '2',
        child: [{
            name: '实时数据',
            key: '2-1',
            auth: false,
            local: true,
            soc: true,
            role: '301',
            u_type: 104,
            icon: < FundProjectionScreenOutlined />,
            path: '/monitor/data'
        }, 
        {
            name: '历史数据',
            key: '2-2',
            auth: false,
            local: true,
            soc: true,
            role: '301',
            u_type: 104,
            icon: < FieldTimeOutlined />,
            path: '/monitor/records'
        }, 
        {
            name: '轨迹位置',
            key: '2-3',
            auth: false,
            local: true,
            soc: true,
            role: '301',
            u_type: 104,
            icon: <NodeIndexOutlined />,
            path: '/monitor/map'
        }, 
        {
            name: '报警数据',
            key: '2-4',
            auth: false,
            local: true,
            soc: true,
            role: '301',
            u_type: 104,
            icon: < AlertOutlined />,
            path: '/monitor/alarm'
        }, 
        {
            name: '操作日志',
            key: '2-5',
            auth: false,
            local: true,
            soc: true,
            role: '301',
            u_type: 103,
            icon: < CarryOutOutlined />,
            path: '/monitor/operation'
        }, 
        {
            name: '报警联系人',
            key: '2-6',
            auth: false,
            local: true,
            soc: false,
            role: '301',
            u_type: 105,
            icon: < TeamOutlined />,
            path: '/manage/alarmuser'
        }, 
        // {
        //     name: '备份管理',
        //     key: '2-6',
        //     auth: false,
        //     local: true,
        //     soc: true,
        //     u_type: 104,
        //     icon: < DeliveredProcedureOutlined />,
        //     path: '/monitor/backup'
        // },
        // {
        //     name: '续费管理',
        //     key: '2-7',
        //     auth: false,
        //     local: false,
        //     soc: true,
        //     u_type: 104,
        //     icon: < DeliveredProcedureOutlined />,
        //     path: '/monitor/pay'
        // }
    ]},
    // 管理
    {
        name: '管理',
        path: '/manage',
        auth: false,
        local: true,
        u_type: 103,
        icon: < BranchesOutlined />,
        key: '3',
        child: [
               
        {
            name: '订单管理',
            key: '3-3',
            auth: false,
            local: false,
            soc: true,
            role: '306',
            u_type: 103,
            icon: <PullRequestOutlined />,
            path: '/manage/transport'
        },  {
            name: '视频管理',
            key: '3-4',
            auth: false,
            local: false,
            soc: true,
            role: '311',
            u_type: 103,
            icon: < VideoCameraOutlined />,
            path: '/manage/video'
        }, {
            name: '用户管理',
            key: '3-6',
            auth: false,
            local: false,
            soc: true,
            role: '309',
            u_type: 103,
            icon: < TeamOutlined />,
            path: '/manage/user'
        },{
            name: '区域管理',
            key: '3-1',
            auth: false,
            local: true,
            soc: false,
            role: '308',
            u_type: 103,
            icon: < ControlOutlined />,
            path: '/manage/area'
        },
            {
            name: '设备管理',
            key: '3-2',
            auth: false,
            local: true,
            soc: true,
            role: '307',
            u_type: 103,
            icon: < ControlOutlined />,
            path: '/manage/device'
        }, {
            name: '报警管理',
            key: '3-7',
            auth: false,
            local: false,
            soc: true,
            role: '302',
            u_type: 103,
            icon: < TeamOutlined />,
            path: '/manage/alarmuser'
        }, {
            name: '平面管理',
            key: '3-5',
            auth: false,
            local: true,
            soc: true,
            role: '310',
            u_type: 103,
            icon: < UngroupOutlined />,
            path: '/manage/hmi'
        },  
        {
            name: '用户管理',
            key: '3-10',
            auth: false,
            local: true,
            soc: false,
            role: '309',
            u_type: 103,
            icon: < BarsOutlined />,
            path: '/manage/localuser'
        },
        
    ]},
     // 帮助
    {
        name: '帮助',
        key: '4',
        path: '/help',
        auth: false,
        local: false,
        role: '301',
        u_type: 104,
        icon: <QuestionCircleOutlined />,
    }, 
]
export default menu;